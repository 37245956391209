/* eslint-disable no-unreachable */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import axios from 'axios'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import config from 'config'
import { message } from 'antd'
import vaults from '../config/vaults'
import pools from '../config/pools'
import cachedLpPrice from '../config/cachedLpPrice'
import abi from './allabis'
import addressV2 from '../config/v2/address'

const blocksPerDay = 6500

export const cBN = (val) => new BigNumber(val)

export const AddressZero = '0x0000000000000000000000000000000000000000'

export function formatBalanceV2(balanceInWei, decimals = 18, toFixed = -1) {
  if (cBN(balanceInWei).isNaN()) return '0'

  const formatResult = (result) => {
    if (cBN(result).isZero() || Number.isNaN(result) || result === 'NaN') {
      return '0'
    }

    const trimZero = result.split('.').length > 1 ? result.replace(/0+?$/gi, '').replace(/[.]$/gi, '') : result

    if (cBN(result).isLessThan(1)) {
      return `${trimZero}`
    }

    return trimZero
  }

  if (toFixed === -1) {
    const result = cBN(balanceInWei).div(cBN(10).pow(decimals)).toFormat()

    return formatResult(result)
  }

  const result = cBN(balanceInWei).div(cBN(10).pow(decimals)).toFormat(toFixed)

  return formatResult(result)
}

// TODO
export const fb4 = (balance, isMoney = false, decimals) => {
  if (cBN(balance).isZero()) {
    return isMoney ? '$0' : '-'
  }
  if (cBN(balance).isNaN()) {
    return isMoney ? '$0' : '-'
  }

  return `${isMoney ? '$' : ''}${formatBalanceV2(balance, decimals ?? 18, isMoney ? 2 : 4)}`
}

export const checkNotZoroNum = (num) => {
  if (!num) {
    return false
  }
  return !(cBN(num).isZero() || cBN(num).isNaN() || !cBN(num).isFinite())
}

export const checkNotZoroNumOption = (num, cb) => {
  if (checkNotZoroNum(num)) {
    return cb
  }
  return '-'
}

// outside call times = 3
export const getUniswapLPPrice = async (web3, lpAddress, underlyingAssets) => {
  if (!web3) {
    console.error('[getUniswapLPPrice] missing web3', web3)
    return 0
  }
  if (!lpAddress) {
    console.error('[getUniswapLPPrice] missing lpAddress', lpAddress)
    return 0
  }
  if (config.enableCachedLpPrice && cachedLpPrice[lpAddress]) {
    console.log('[getUniswapLPPrice]', lpAddress, ' use cached lp price', cachedLpPrice[lpAddress])
    return cachedLpPrice[lpAddress]
  }

  let lpPrice = 0
  try {
    const lpTokenContract = new web3.eth.Contract(abi.erc20ABI, lpAddress)
    const lpTotalSupplyInWei = await lpTokenContract.methods.totalSupply().call()
    if (cBN(lpTotalSupplyInWei).isLessThanOrEqualTo(0)) return 0
    const poolValueInUSD = (
      await Promise.all(
        // underlyingAssets 0:
        underlyingAssets.map(async (asset) => {
          const tokenPrice = (await getTokenPrice(asset[0])) || 0
          const tokenDecimal = asset[2]
          let tokenAmountInWei = 0
          if (asset[1] === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
            tokenAmountInWei = await web3.eth.getBalance(lpAddress)
          } else {
            const underlyingAssetContract = new web3.eth.Contract(abi.erc20ABI, asset[1])
            tokenAmountInWei = await underlyingAssetContract.methods.balanceOf(lpAddress).call()
            console.log('[getUniswapLPPrice] get token price', asset, tokenAmountInWei)
          }
          const tokenAmount = cBN(tokenAmountInWei).div(cBN(10).pow(tokenDecimal))
          console.log('[getUniswapLPPrice] token amount', asset, tokenAmount.toString(), 'token price', tokenPrice)
          const assetValueInPool = cBN(tokenPrice).multipliedBy(tokenAmount).toString()
          console.log('[getUniswapLPPrice]', lpAddress, 'assetValueInPool', asset, assetValueInPool)
          return assetValueInPool
        }),
      )
    ).reduce((a, b) => {
      return cBN(a).plus(b)
    })
    lpPrice = cBN(poolValueInUSD)
      .div(cBN(lpTotalSupplyInWei).div(cBN(10).pow(18)))
      .toFixed(4)
    console.log('[getUniswapLPPrice]', lpAddress, 'lpPrice', lpPrice.toString())
  } catch (e) {
    console.error('[getUniswapLPPrice]', lpAddress, e.toString())
  }
  return lpPrice
}

// outside call times = 3/4
export const getCurveLPPrice2 = async (web3, lpAddress, swapPoolABI, swapPoolContractAddress, underlyingAssets) => {
  // console.log('[getCurveLPPrice2] params', lpAddress, swapPoolABI, swapPoolContractAddress, underlyingAssets)
  if (!web3) {
    console.error('[getCurveLPPrice2] missing web3', web3)
    return 0
  }
  if (!lpAddress) {
    console.error('[getCurveLPPrice2] missing lpAddress', lpAddress)
    return 0
  }
  if (config.enableCachedLpPrice && cachedLpPrice[lpAddress]) {
    console.log('[getCurveLPPrice2]', lpAddress, ' use cached lp price', cachedLpPrice[lpAddress])
    return cachedLpPrice[lpAddress]
  }
  if (!swapPoolABI) {
    console.error('missing swap pool abi', lpAddress)
    return 0
  }

  let lpPrice = 0
  try {
    const lpTokenContract = new web3.eth.Contract(abi.erc20ABI, lpAddress)
    const curvePoolContract = new web3.eth.Contract(swapPoolABI, swapPoolContractAddress)

    // console.log('[curve lp price]', lpAddress, 'underlyingAssetsContract', underlyingAssetsContract)
    const lpTotalSupply = await lpTokenContract.methods.totalSupply().call()
    if (cBN(lpTotalSupply).isLessThanOrEqualTo(0)) return 0
    // console.log('[curve lp price]', lpAddress, 'lpTotalSupply', lpTotalSupply.toString())

    // Get Curve Vault Total Value
    let totalAssetValueInUSD = cBN(0)
    for (let i = 0; i < underlyingAssets.length; i += 1) {
      console.log('[curve lp price]', lpAddress, 'getting underlyng asset price', 'underlyingAsset index', i)
      const assetBalanceInWei = await curvePoolContract.methods.balances(i).call()
      let tokenPrice = 1
      const [tokenId, tokenAddress, tokenDecimal] = underlyingAssets[i]
      // for stablecoins, can use 1 directly to reduce the outside call
      if (['dai', 'tether', 'usd-coin'].indexOf(tokenId) < 0) {
        tokenPrice = await getTokenPrice(tokenId)
        console.log('[curve lp price]', lpAddress, 'underlyingAsset', i, 'tokenId', tokenId, 'tokenPrice', tokenPrice)
        totalAssetValueInUSD = totalAssetValueInUSD.plus(
          cBN(tokenPrice).multipliedBy(cBN(assetBalanceInWei).div(cBN(10).pow(tokenDecimal))),
        )
      } else {
        totalAssetValueInUSD = totalAssetValueInUSD.plus(cBN(assetBalanceInWei).div(cBN(10).pow(tokenDecimal)))
      }
    }

    const lpDecimal = 18
    lpPrice = cBN(totalAssetValueInUSD).div(cBN(lpTotalSupply).div(cBN(10).pow(lpDecimal))) // lp basiccly is 18 decimal
    console.log(
      '[getCurveLPPrice2]',
      lpAddress,
      'totalAssetValueInUSD',
      totalAssetValueInUSD.toString(),
      'lp total supply',
      lpTotalSupply.toString(),
      'lpPrice',
      lpPrice.toString(),
    )
  } catch (e) {
    console.error('[getCurveLPPrice2]', lpAddress, e.toString())
  }
  return lpPrice
}

export const getUniswapLPPriceByVault = async (id, web3) => {
  const vault = vaults.find((item) => item.tvlPriceTokenId === id)
  console.log('[getUniswapLPPriceByVault]', id, vault)
  if (!vault) {
    console.error('[getUniswapLPPriceByVault] empty vault info', id, vault)
    return 0
  }

  const lpPrice = await getUniswapLPPrice(web3, vault.stakeTokenAddress, vault.underlyingAssets)
  return lpPrice
}

export const getUniswapLPPriceByStaking = async (id, web3) => {
  const pool = pools.find((item) => item.tvlPriceTokenId === id)
  console.log('[getUniswapLPPriceByStaking]', id, pool)
  if (!pool) {
    console.error('[getUniswapLPPriceByStaking] empty vault info', id, pool)
    return 0
  }

  console.log(
    '[getUniswapLPPriceByStaking] goes to pool.stakeTokenContractAddress, pool.underlyingAssets',
    pool.stakeTokenContractAddress,
    pool.underlyingAssets,
  )
  const lpPrice = await getUniswapLPPrice(web3, pool.stakeTokenContractAddress, pool.underlyingAssets)
  console.log('[getUniswapLPPriceByStaking] lpPrice', id, lpPrice)
  return lpPrice
}

// underlyingAssets should follow the order of swap contract index
const curveLpInfo = {
  [config.tokens.stETHCRV]: {
    address: config.tokens.stETHCRV,
    swapPoolABI: abi.curve3poolswapABI,
    swapPoolAddress: config.contracts.curveSETHPoolSwap,
    underlyingAssets: [
      ['ethereum', config.tokens.eth, 18],
      ['ethereum', config.tokens.steth, 18],
    ],
  },
  [config.tokens.crvRenWBTC]: {
    address: config.tokens.crvRenWBTC,
    swapPoolABI: abi.curveRenBTCSwapABI,
    swapPoolAddress: config.contracts.curveRENPoolSwap,
    underlyingAssets: [
      ['bitcoin', config.tokens.renBTC, 8],
      ['bitcoin', config.tokens.wbtc, 8],
    ],
  },
  [config.tokens.threeCRV]: {
    address: config.tokens.threeCRV,
    swapPoolABI: abi.curve3poolswapABI,
    swapPoolAddress: config.contracts.curve3PoolSwap,
    underlyingAssets: [
      ['dai', config.tokens.dai, 18],
      ['usd-coin', config.tokens.usdc, 6],
      ['tether', config.tokens.usdt, 6],
    ],
  },
  [config.tokens.tricrypto2]: {
    address: config.tokens.tricrypto2,
    swapPoolABI: abi.curve3poolswapABI,
    swapPoolAddress: config.contracts.curveTricrypto2PoolSwap,
    underlyingAssets: [
      ['tether', config.tokens.usdt, 6],
      ['wrapped-bitcoin', config.tokens.wbtc, 8],
      ['weth', config.tokens.weth, 18],
    ],
  },
}

export const getCurveLPPriceByTokenId = async (tvlPriceTokenId, web3) => {
  if (!web3) {
    console.error('[getCurveLPPriceByTokenId] missing web3', web3)
  }
  console.log('[getCurveLPPriceByTokenId] starts', tvlPriceTokenId)
  const vault = await vaults.find((item) => {
    return item.tvlPriceTokenId === tvlPriceTokenId
  })
  const lpName = tvlPriceTokenId.slice(8)
  const lpAddress = config.tokens[lpName]

  if (!lpAddress) {
    console.error('[getCurveLPPriceByTokenId] can not find id', tvlPriceTokenId, lpAddress)
    return 0
  }
  const info = curveLpInfo[lpAddress]
  // console.log('[getCurveLPPriceByTokenId] lp info', lpAddress, info)
  if (!info) {
    console.error('[getCurveLPPriceByTokenId] missing curve lp info', tvlPriceTokenId, lpAddress, info)
    return 0
  }

  const lpPrice = await getCurveLPPrice2(web3, lpAddress, info.swapPoolABI, info.swapPoolAddress, info.underlyingAssets)
  // console.log('[getCurveLPPriceByTokenId] vault vault_id', vault.vault_id, 'lp price: ', lpPrice.toString())
  return lpPrice
}

export const getAladdinLPPrice = async (id, web3) => {
  // console.log('[getAladdinLPPrice] id', id)
  if (!id.startsWith('ald')) {
    throw new Error('Invalid aladdin token id')
  }
  const vault = vaults.find((item) => {
    return item.vaultTokenSymbol === id
  })
  if (!vault) {
    console.error('[getAladdinLPPrice] error: can not find vault by id', id, vault)
    // maybe it's uniswap token
    return 0
  }
  const lpPrice = await getLPTokenPrice(web3, vault.tvlPriceTokenId)
  console.log('[getAladdinLPPrice] id', id, 'lpPrice', lpPrice.toString(), 'vault.tvlPriceTokenId', vault.tvlPriceTokenId)
  return lpPrice.toString()
}

export async function getALDPrice() {
  let aldPrice = 0
  try {
    aldPrice = await getTokenPrice('aladdin-dao')
  } catch (e) {
    console.error('failed to get ald price', e.toString())
  }
  return aldPrice
}

// export async function getLPPrice(token0Address, token0Decimal, token1Address, token1Decimal) {
//   const token0 = new Token(ChainId.MAINNET, token0Address, token0Decimal)

//   const token1 = new Token(ChainId.MAINNET, token1Address, token1Decimal)

//   const pair = await Fetcher.fetchPairData(token0, token1)

//   console.log('pair', pair.token0, pair.token1, pair.reserve0.toExact(), pair.reserve1.toExact())

//   const route1 = new Route([pair], WETH[token0.chainId])

//   const route2 = new Route([pair], WETH[token0.chainId])

//   return pair
// }

const getCachedPrice = (id) => {
  try {
    const cachedData = JSON.parse(localStorage.getItem(id))
    const cacheTime = 30000
    if (new Date().getTime() - cachedData.lastUpdate <= cacheTime) {
      return cachedData.lastPrice
    }
  } catch (e) {
    console.error('error to get cahced price', e.toString())
  }
  return null
}

// Get from coingecko
export async function getTokenPrice(id) {
  if (id === 'ald') {
    return getALDPrice()
  }
  const cachedPrice = getCachedPrice(id)
  if (cachedPrice !== null) {
    console.log('[getTokenPrice] use cahced data', id, cachedPrice)
    return cachedPrice
  }
  return new Promise(async (resolve) => {
    const res = await axios
      .get(`${config.coingeckoURL}/simple/price`, {
        params: {
          ids: id,
          vs_currencies: 'usd',
        },
      })
      .catch((e) => {})
    console.log('[getTokenPrice] get and set price', id, res?.data[id]?.usd)
    localStorage.setItem(
      id,
      JSON.stringify({
        lastPrice: res?.data[id]?.usd,
        lastUpdate: new Date().getTime(),
      }),
    )
    resolve(res?.data[id]?.usd)
  })
}
// export async function getLPLiquidValue(pair, ) {
//   const token0 = new Token(ChainId.MAINNET, token0Address, token0Decimal)

//   const token1 = new Token(ChainId.MAINNET, token1Address, token1Decimal)

//   const pair = await Fetcher.fetchPairData(token0, token1)

//   console.log('pair, mid price:', pair.midPrice.toSignificant(6), '', '')

//   return pair
// }

export const calcStakingAPY = (derivedETH, aldPerBlock, allocPoint, totalAllocPoint, totalValueETH, slpBalance, totalSupply) => {
  return (
    (derivedETH * blocksPerDay * aldPerBlock * 365 * (allocPoint / totalAllocPoint)) /
    (totalValueETH * (slpBalance / totalSupply))
  )
}

/**
 * Get LP Token Price by token id (set in vault config)
 * tokenId: {protocol}-{lpTokenName}
 * @param {*} web3
 * @param {*} tokenId
 * @returns
 */
export async function getLPTokenPrice(web3, tokenId) {
  let price = 0
  if (!tokenId) {
    return 0
  }

  if (tokenId === 'ald') {
    price = await getALDPrice()
  } else if (tokenId.startsWith('ald')) {
    price = await getAladdinLPPrice(tokenId, web3)
  } else if (tokenId.startsWith('uniLP-')) {
    price = await getUniswapLPPriceByStaking(tokenId, web3)
  } else if (tokenId.startsWith('curveLP-')) {
    price = await getCurveLPPriceByTokenId(tokenId, web3)
  } else if (tokenId.startsWith('slp-')) {
    price = await getUniswapLPPriceByVault(tokenId, web3)
  } else {
    price = await getTokenPrice(tokenId)
  }
  console.log('[getLPTokenPrice]', tokenId, price.toString())
  return price
}

/**
 * Get the Total Value Locked (TVL) of a vault v1
 * TVLinUSD = vaultLPBalance * LPPriceInUSD
 */
export async function getVaultTVL(web3, vaultId) {
  if (!vaultId) {
    console.error('[getVaultTVL] Invalid vault id', vaultId)
    return 0
  }
  const currentVaultInfo = vaults.find((item) => item.vault_id === vaultId)
  if (!currentVaultInfo) {
    console.error('[getVaultTVL] Can not find matched vault info', vaultId)
    return 0
  }
  try {
    const { tvlPriceTokenId, vaultContractAddress, vaultTokenDecimals } = currentVaultInfo
    const vaultContract = new web3.eth.Contract(abi.vaultABI, vaultContractAddress)

    const balanceInWei = await vaultContract.methods.balance().call()
    const vaultLPBalance = cBN(balanceInWei).div(cBN(10).pow(vaultTokenDecimals))
    console.log(
      '[getVaultTVL]',
      currentVaultInfo.vault_id,
      'get vault lp balance in wei',
      balanceInWei.toString(),
      'decimal',
      vaultTokenDecimals,
    )

    const vaultLPTokenPrice = await getLPTokenPrice(web3, tvlPriceTokenId)
    console.log(
      '[getVaultTVL]',
      currentVaultInfo.vault_id,
      'get vault lp token price',
      'tvlPriceTokenId',
      tvlPriceTokenId,
      'price',
      vaultLPTokenPrice.toString(),
    )

    const vaultTVLinUSD = vaultLPBalance.multipliedBy(vaultLPTokenPrice)

    return vaultTVLinUSD.toString()
  } catch (e) {
    console.error('[getVaultTVL] fetch vault tvl error', vaultId, e)
    return 0
  }
}

/**
 * Get the sum of Total Value Locked (TVL) of all Aladdin Vaults
 * TVLinUSD = vaultLPBalance * LPPriceInUSD
 */
export async function getAllVaultsTVL(web3, vaultIds) {
  console.log('[getAllVaultsTVL] getAllVaultsTVL triggered', vaultIds)

  const totalTVLinUSD = await vaultIds.reduce(async (tvlAccum, currentVaultId, index) => {
    const vaultTVLinUSD = await getVaultTVL(web3, currentVaultId)
    console.log('[getAllVaultsTVL] vaultsTVLinUSD for index,', index, 'is', vaultTVLinUSD.toString())
    return cBN(await tvlAccum).plus(vaultTVLinUSD)
  }, cBN(0))

  console.log('[getAllVaultsTVL] result totalTVLinUSD: ', totalTVLinUSD.toString())

  return totalTVLinUSD.toString()
}

/**
 * getStakingPoolTVL
 * TVL = lpTokenBalance * lpTokenPrice
 * @param {web3} web3
 * @param {*} poolId
 * @returns
 */
export async function getStakingPoolTVL(web3, poolId) {
  const poolInfo = pools.find((item) => item.pool_id === poolId)
  if (!poolInfo) {
    console.error('[getStakingPoolTVL] can not find pool info,', poolId, poolInfo)
    return 0
  }

  const poolTokenContract = new web3.eth.Contract(abi.erc20ABI, poolInfo.stakeTokenContractAddress)
  console.log('[getStakingPoolTVL]', poolId, 'poolInfo.stakeTokenContractAddress', poolInfo.stakeTokenContractAddress)

  const poolBalanceInWei = await poolTokenContract.methods.balanceOf(config.contracts.tokenMaster).call()
  const poolBalance = cBN(poolBalanceInWei).div(cBN(10).pow(poolInfo.stakeTokenDecimals))
  console.log('[getStakingPoolTVL]', poolId, 'poolBalanceInWei', poolBalanceInWei)

  let poolTokenPrice = 0
  if (poolId === 2) {
    poolTokenPrice = await getLPTokenPrice(web3, 'slp-eth-wbtc')
  } else {
    poolTokenPrice = await getLPTokenPrice(web3, poolInfo.stakeTokenSymbol)
  }
  console.log('[getStakingPoolTVL]', poolId, 'poolTokenPrice', poolTokenPrice, 'tokenId', poolInfo.stakeTokenSymbol)
  const totalValueInUSD = poolBalance.multipliedBy(poolTokenPrice)

  console.log(
    '[getStakingPoolTVL] pool_id',
    poolInfo.pool_id,
    'poolBalance',
    poolBalance.toString(),
    'poolTokenPrice',
    poolTokenPrice.toString(),
    'totalValueInUSD',
    totalValueInUSD.toString(),
  )

  return totalValueInUSD.toString()
}

export async function getStakingPoolYearlyGeneratedValue(web3, poolId, totalAllocPointData, actualAldPerBlockData) {
  const poolLocalInfo = pools.find((item) => item.pool_id === poolId)
  if (!poolLocalInfo) {
    console.error('[getStakingPoolTVL] can not find pool info,', poolId, poolLocalInfo)
    return 0
  }
  const tokenMasterContract = new web3.eth.Contract(abi.tokenMasterABI, config.contracts.tokenMaster)

  const poolInfo = await tokenMasterContract.methods.poolInfo(poolId).call()
  const poolAllocPoint = poolInfo.allocPoint
  console.log('[getStakingPoolYearlyGeneratedValue]', poolId, 'poolAllocPoint', poolAllocPoint)
  const poolAllocatedRatio = poolAllocPoint / totalAllocPointData
  const poolAllocatedALDPerBlock = poolAllocatedRatio * actualAldPerBlockData
  console.log('[getStakingPoolYearlyGeneratedValue]', poolId, 'poolAllocatedRatio', poolAllocatedRatio, poolAllocatedALDPerBlock)

  // Todo:: use real ald price
  const aldPriceInUSD = await getALDPrice()
  const perBlockALDValueInUSD = poolAllocatedALDPerBlock * aldPriceInUSD
  const dailyALDValueInUSD = 6500 * perBlockALDValueInUSD
  const yearlyALDValueInUSD = dailyALDValueInUSD * 365

  return yearlyALDValueInUSD
}

export async function getAllStakingPoolsTVL(web3) {
  const totalTVLinUSD = await pools.reduce(async (tvlAccum, current) => {
    const poolTVLinUSD = await getStakingPoolTVL(web3, current.pool_id)
    console.log('[getAllStakingPoolsTVL] poolTVLinUSD for pool_id', current.pool_id, 'is', poolTVLinUSD.toString())
    return cBN(await tvlAccum).plus(poolTVLinUSD)
  }, cBN(0))

  console.log('[getAllStakingPoolsTVL] result totalTVLinUSD: ', totalTVLinUSD.toString())

  return totalTVLinUSD.toString()
}

export async function getRewardsPoolTVL(multiRewardsContract) {
  const totalALDStaked = await multiRewardsContract.methods.totalSupply() // total ALD staked
  const ALDPriceInUSD = await getALDPrice()
  return cBN(ALDPriceInUSD).multipliedBy(totalALDStaked).toString()
}

export async function getAladdinTVL(web3, vaultIds, multiRewardsContract) {
  const vaultsTVLInUsd = await getAllVaultsTVL(web3, vaultIds)
  const rewardsPoolTVL = await getRewardsPoolTVL(multiRewardsContract)
  const totalAladdinTVLInUSD = cBN(vaultsTVLInUsd).plus(rewardsPoolTVL)
  return totalAladdinTVLInUSD
}

export function formatBalance(balanceInWei, decimals = 18, toFixed = -1) {
  // if (cBN(balanceInWei).isZero() return '🌕'
  // if (cBN(balanceInWei).isNaN()) return '🌕'

  if (toFixed === -1) {
    const result = cBN(balanceInWei).div(cBN(10).pow(decimals)).toFormat()
    return Number.isNaN(result) || result === 'NaN' ? 0 : result
  }

  const result = cBN(balanceInWei).div(cBN(10).pow(decimals)).toFormat(toFixed)
  return Number.isNaN(result) || result === 'NaN' ? 0 : result
}

export function formatAddress(address, n) {
  return `${address.slice(0, n + 2)}...${address.slice(-n)}`
}

export function formatDuration(duration) {
  if (cBN(duration).isLessThanOrEqualTo(0)) {
    return '--'
  }
  const d = moment.duration(duration, 'seconds')
  const day = Math.floor(d.asDays())
  const hour = d.hours()
  const minutes = d.minutes()
  const seconds = d.seconds()

  if (day) {
    return `${day}天${hour}时${minutes}分${seconds}秒`
  }
  if (hour) {
    return `${hour}时${minutes}分${seconds}秒`
  }
  if (minutes) {
    return `${minutes}分${seconds}秒`
  }
  if (seconds) {
    return `${seconds}秒`
  }

  return `--`
}

export function formatDurationEn(duration) {
  if (cBN(duration).isLessThanOrEqualTo(0)) {
    return '--'
  }
  const d = moment.duration(duration, 'seconds')
  const day = Math.floor(d.asDays())
  const hour = d.hours()
  const minutes = d.minutes()
  // const seconds = d.seconds()

  if (day) {
    return `${day} Days, ${hour}h, ${minutes}m`
  }
  if (hour) {
    return `${hour}h, ${minutes}m`
  }
  if (minutes) {
    return `${minutes}m`
  }

  return `--`
}

// returns true or false
// Todo:: remove web3 dependency
export function isAddress(value, web3) {
  return true
  if (!web3) return false
  return web3.utils.isAddress(value)
}

export const basicCheck = (web3, currentAccount) => {
  if (!web3) {
    message.error('Web3 not detected')
    return false
  }
  if (!currentAccount) {
    message.error('Not connected to a valid account')
    return false
  }
  return true
}

export default {
  cBN,
  formatBalance,
  getTokenPrice,
  getVaultTVL,
  getAllVaultsTVL,
  getStakingPoolTVL,
  getAllStakingPoolsTVL,
  calcStakingAPY,
  getStakingPoolYearlyGeneratedValue,
  isAddress,
  formatAddress,
  AddressZero,
  formatDuration,
  formatDurationEn,
  basicCheck,
}
