/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react'
import { Web3Context } from 'context/Web3Context'
import { Tag, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import config from 'config'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import LightIcon from 'assets/light-icon.svg'
import DarkIcon from 'assets/dark-icon.svg'
// import FavPages from './FavPages'
// import Search from './Search'
// import IssuesHistory from './IssuesHistory'
// import ProjectManagement from './ProjectManagement'
import BalanceModal from './BalanceModal'
import LanguageSwitcher from './LanguageSwitcher'
import { formatBalance } from '../../../../utils'
// import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => {
  const { currentChainId, aldBalance } = useContext(Web3Context)
  const [wrongNetwork, setWrongNetwork] = useState(false)
  const [balanceModalVisible, setBalanceModalVisible] = useState(false)
  const theme = useSelector(state => state.settings.theme)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (config.CHAIN_ID !== currentChainId) {
      setWrongNetwork(true)
    } else {
      setWrongNetwork(false)
    }
  }, [currentChainId])

  const setTheme = nextTheme => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'dark' ? 'dark' : 'light',
      },
    })
  }

  return (
    <div className={style.topbar}>
      <div className={style.v1LinkWrapper}>
        <a className={style.v1Link} href="https://app.v1.aladdin.club" target="_blank" rel="noopener noreferrer">
          Aladdin v1 &gt;
        </a>
      </div>
      <div className="container logo-wrapper">
        <Link to="/home" className={style.logoLink}>
          <img
            className={style.logo}
            src={theme === 'dark' ? 'resources/images/logo-dark.svg' : 'resources/images/logo-light.svg'}
            alt="Aladdin Logo"
          />
        </Link>

        <div className="balance-tag">
          {wrongNetwork ? (
            <Tag color="#ff0000" className="wrong-network-tag">
              <span>{config.CHAIN_MAPPING[currentChainId] || 'WRONG NETWORK'}</span>
              {wrongNetwork && (
                <Tooltip title="Please switch your wallet network">
                  <ExclamationCircleOutlined style={{ verticalAlign: 'middle', marginLeft: '4px' }} />
                </Tooltip>
              )}
            </Tag>
          ) : (
            <Tag onClick={() => setBalanceModalVisible(true)} style={{ cursor: 'pointer' }} color="#b78b49">
              {formatBalance(aldBalance, 0, 0)} ALD
            </Tag>
          )}
        </div>
      </div>

      {location.pathname !== '/home' && (
        <div className="mr-4 ml-2">
          <a
            tabIndex={0}
            onKeyDown={() => setTheme(theme === 'default' ? 'dark' : 'default')}
            role="button"
            onClick={() => setTheme(theme === 'default' ? 'dark' : 'default')}
          >
            <img src={theme === 'default' ? LightIcon : DarkIcon} alt="switch-theme" className="switch-theme-icon" />
          </a>
        </div>
      )}

      <div className="mr-4 d-none d-sm-block">
        <LanguageSwitcher />
      </div>

      <div className="">
        <UserMenu />
      </div>
      {balanceModalVisible && <BalanceModal onCancel={() => setBalanceModalVisible(false)} />}
    </div>
  )
}

export default TopBar
