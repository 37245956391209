import config from '../index'

// Make sure the info matches with the contract data
const vaults = [
  {
    logo: '‍🔐',
    logoImg: '/resources/tokens/comp.svg',
    vault_id: 0,
    type: 'vault',
    name: 'Compound USDT Vault',
    // description: 'Deposit USDT and earn Comp',
    deposit: 'USDT',
    apy: 10.12,
    tvl: '0',
    price: '$0',
    tokens: ['usdt'],
    tokensById: ['tether'], // ids on coingecko
    tvlPriceTokenId: 'tether',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCompoundUSDT,
    stakeTokenAddress: config.tokens.usdt,
    stakeTokenSymbol: 'USDT',
    stakeTokenDecimals: 6,
    vaultTokenSymbol: 'aldcUSDT',
    vaultTokenDecimals: 6,
    rewardTokenSymbol: 'Comp',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  {
    logo: '🔐‍',
    logoImg: '/resources/tokens/comp.svg',
    vault_id: 1,
    type: 'vault',
    name: 'Compound DAI Vault',
    // description: 'Deposit DAI and earn Comp',
    deposit: 'DAI',
    apy: 10.12,
    tvl: '0',
    price: '$0',
    tokens: ['dai'],
    tokensById: ['dai'], // ids on coingecko
    tvlPriceTokenId: 'dai',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCompoundDai,
    stakeTokenAddress: config.tokens.dai,
    stakeTokenSymbol: 'USDT',
    stakeTokenDecimals: 6,
    vaultTokenSymbol: 'aldcUSDT',
    vaultTokenDecimals: 6,
    rewardTokenSymbol: 'Comp',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  {
    logo: '🔐',
    logoImg: '/resources/tokens/comp.svg',
    vault_id: 2,
    type: 'vault',
    name: 'Compound WBTC Vault',
    // description: 'Deposit WBTC and earn Comp',
    deposit: 'WBTC',
    apy: 10.12,
    tvl: '0',
    price: '$0',
    tokens: ['btc'],
    tokensById: ['bitcoin'], // ids on coingecko
    tvlPriceTokenId: 'bitcoin',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCompoundWBTC,
    stakeTokenAddress: config.tokens.wbtc,
    stakeTokenSymbol: 'USDT',
    stakeTokenDecimals: 6,
    vaultTokenSymbol: 'aldcUSDT',
    vaultTokenDecimals: 6,
    rewardTokenSymbol: 'Comp',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  {
    logo: '👨‍',
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 3,
    name: 'Curve renWBTC Vault',
    // description: 'Deposit ',
    deposit: '',
    apy: 10.12,
    tvl: '0',
    type: 'vault',
    price: '$0',
    tokens: ['btc'],
    // ids on coingecko
    tokensById: ['bitcoin'],
    tvlPriceTokenId: 'bitcoin',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCurveRenWBTC,
    stakeTokenAddress: config.tokens.crvRenWBTC,
    stakeTokenSymbol: 'crvRenWBTC',
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldcrvRenWBTC',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    logo: '👨‍',
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 4,
    name: 'Curve HBTC Vault',
    // description: 'Deposit Curve.fi HBTC/WBTC LP to earn CRV',
    deposit: 'HBTC/WBTC',
    apy: 10.12,
    tvl: '0',
    type: 'vault',
    price: '$1',
    tokens: ['usdt'],
    // ids on coingeckP
    tokensById: ['tether'],
    tvlPriceTokenId: 'tether',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCurveHBTC,
    stakeTokenAddress: config.tokens.threeCRV,
    stakeTokenSymbol: '3CRV',
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'ald3CRV',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    logo: '👨‍',
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 5,
    name: 'Curve SETH Vault',
    // description: 'Deposit Curve.fi ETH/SETH LP (eCRV) to earn CRV',
    deposit: 'ETH/SETH',
    apy: 10.12,
    tvl: '0',
    type: 'vault',
    price: '$0',
    tokens: ['eth'],
    // ids on coingecko
    tokensById: ['ethereum'],
    tvlPriceTokenId: 'ethereum',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCurveSETH,
    stakeTokenAddress: config.tokens.eCRV,
    stakeTokenSymbol: 'eCRV',
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldeCRV',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    logo: '👨‍',
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 6,
    name: 'Curve 3Pool Vault',
    // description: 'Deposit USDT to earn CRV',
    deposit: 'USDT',
    apy: 10.12,
    tvl: '0',
    type: 'vault',
    price: '$1',
    tokens: ['usdt'],
    // ids on coingeckP
    tokensById: ['tether'],
    tvlPriceTokenId: 'tether',
    tokensPrices: [0],
    vaultContractAddress: config.contracts.vaultCurve3Pool,
    stakeTokenAddress: config.tokens.usdt,
    stakeTokenSymbol: 'USDT',
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'ald3CRV',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    logo: '‍🍣',
    vault_id: 7,
    name: 'Sushi ETH/USDC LP',
    // description: 'Test Sushi ETH/USDC LP Example',
    deposit: 'ETH/USDC',
    apy: 10.12,
    tvl: '0',
    type: 'vault',
    price: '$1',
    tokens: ['eth', 'usdt'],
    // ids on coingecko
    tokensById: ['ethereum', 'tether'],
    tvlPriceTokenId: 'ethereum',
    tokensPrices: [0, 0],
    vaultContractAddress: config.contracts.vaultSushiETHUSDC,
    stakeTokenAddress: config.tokens.slpETHUSDC,
    stakeTokenSymbol: 'ETH/USDC SLP',
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldETH/USDC SLP',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'sushi',
    rewardTokenDecimals: 18,
    site: 'sushi',
  },
  {
    logo: '‍🍣',
    vault_id: 8,
    name: 'Sushi ETH/WBTC LP',
    // description: 'Test Sushi ETH/WBTC LP Example',
    deposit: 'ETH/WBTC',
    apy: 10.12,
    tvl: '0',
    type: 'vault',
    price: '$1',
    tokens: ['eth', 'usdt'],
    // ids on coingecko
    tokensById: ['ethereum', 'dai'],
    tvlPriceTokenId: 'ethereum',
    tokensPrices: [0, 0],
    vaultContractAddress: config.contracts.vaultSushiETHWBTC,
    stakeTokenAddress: config.tokens.slpETHWBTC,
    stakeTokenSymbol: 'ETH/USDC SLP',
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldETH/USDC SLP',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'sushi',
    rewardTokenDecimals: 18,
    site: 'sushi',
  },
]

export default vaults
