import config from '../index'

// Make sure the info matches with the contract data
const vaults = [
  {
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 1,
    name: 'Curve renWBTC Vault',
    // description: 'Deposit ',
    apy: 3.26,
    type: 'vault',
    tokens: ['btc'],
    tvlPriceTokenId: 'curveLP-crvRenWBTC',
    vaultContractAddress: config.contracts.vaultCurveRenWBTC,
    underlyingAssetsContract: config.contracts.curveRENPoolSwap,
    underlyingAssets: [
      ['bitcoin', config.tokens.renBTC, 8],
      ['bitcoin', config.tokens.wbtc, 8],
    ],
    stakeTokenSymbol: 'crvRenWBTC',
    stakeTokenAddress: config.tokens.crvRenWBTC,
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldcrvRenWBTC',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    relatedPoolId: 0,
    refOutsideUrl: 'https://curve.fi/ren/deposit',
    site: 'crv',
  },
  {
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 2,
    name: 'Curve SETH Vault',
    // description: 'Deposit Curve.fi ETH/SETH LP (eCRV) to earn CRV',
    apy: 0.91,
    type: 'vault',
    tokens: ['eth'],
    tvlPriceTokenId: 'curveLP-eCRV',
    vaultContractAddress: config.contracts.vaultCurveSETH,
    underlyingAssetsContract: config.contracts.curveSETHPoolSwap,
    underlyingAssets: [
      ['ethereum', config.tokens.eth, 18],
      ['ethereum', config.tokens.seth, 18],
    ],
    stakeTokenSymbol: 'eCRV',
    stakeTokenAddress: config.tokens.eCRV,
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldeCRV',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    relatedPoolId: 3,
    refOutsideUrl: 'https://curve.fi/seth/deposit',
    site: 'crv',
  },
  {
    logoImg: '/resources/tokens/crv.svg',
    vault_id: 3,
    name: 'Curve 3Pool Vault',
    // description: 'Deposit USDT to earn CRV',
    // deposit: 'USDT',
    apy: 3.07,
    type: 'vault',
    tokens: ['usdt', 'usdc', 'dai'],
    tvlPriceTokenId: 'curveLP-3CRV',
    vaultContractAddress: config.contracts.vaultCurve3Pool,
    underlyingAssetsContract: config.contracts.curve3PoolSwap,
    underlyingAssets: [
      ['dai', config.tokens.dai, 18],
      ['usd-coin', config.tokens.usdc, 6],
      ['tether', config.tokens.usdt, 6],
    ],
    stakeTokenSymbol: '3CRV',
    stakeTokenAddress: config.tokens.threeCRV,
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'ald3CRV',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'CRV',
    rewardTokenDecimals: 18,
    relatedPoolId: 1,
    refOutsideUrl: 'https://curve.fi/3pool/deposit',
    site: 'crv',
  },
  {
    logo: '‍🍣',
    vault_id: 4,
    name: 'Sushi ETH/WBTC LP',
    // description: 'Test Sushi ETH/WBTC LP Example',
    // deposit: 'ETH/WBTC',
    apy: 3.25,
    type: 'vault',
    tokens: ['eth', 'btc'],
    tvlPriceTokenId: 'slp-eth-wbtc',
    vaultContractAddress: config.contracts.vaultSushiETHWBTC,
    underlyingAssets: [
      ['ethereum', config.tokens.weth, 18],
      ['bitcoin', config.tokens.wbtc, 8],
    ], // coingecko id, address
    stakeTokenSymbol: 'ETH/WBTC SLP',
    stakeTokenAddress: config.tokens.slpETHWBTC,
    stakeTokenDecimals: 18,
    vaultTokenSymbol: 'aldETH/WBTC SLP',
    vaultTokenDecimals: 18,
    rewardTokenSymbol: 'SUSHI',
    rewardTokenDecimals: 18,
    relatedPoolId: 2,
    refOutsideUrl:
      'https://app.sushi.com/add/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    site: 'sushi',
  },
]

export default vaults
