import config from '../index'

const pools = [
  // Compound
  {
    logo: '💡',
    logoImg: '/resources/tokens/comp.svg',
    pool_id: 0,
    name: 'aldcUSDT Staking',
    description: 'Deposit Compound aldcUSDT Token to get ALD',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdt'],
    tokensById: ['tether'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCompoundUSDT,
    stakeToken: 'USDT',
    stakeTokenSymbol: 'aldcUSDT',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  {
    logo: '💡',
    logoImg: '/resources/tokens/comp.svg',
    pool_id: 7,
    name: 'aldcUSDC Staking',
    description: 'Deposit Compound aldcUSDT Token to get ALD',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdt'],
    tokensById: ['tether'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCompoundUSDC,
    stakeToken: 'USDT',
    stakeTokenSymbol: 'aldcUSDT',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  {
    logo: '💡',
    logoImg: '/resources/tokens/comp.svg',
    pool_id: 8,
    name: 'aldcDai Staking',
    description: 'Deposit Compound aldcUSDT Token to get ALD',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdt'],
    tokensById: ['tether'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCompoundDai,
    stakeToken: 'USDT',
    stakeTokenSymbol: 'aldcUSDT',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  {
    logo: '💡',
    logoImg: '/resources/tokens/comp.svg',
    pool_id: 9,
    name: 'aldcWBTC Staking',
    description: 'Deposit Compound aldcWBTC Token to get ALD',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdt'],
    tokensById: ['tether'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCompoundWBTC,
    stakeToken: 'USDT',
    stakeTokenSymbol: 'aldcUSDT',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'comp',
  },
  // Curve
  {
    logo: '👸',
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 1,
    name: 'aldCurve Aave 3pool',
    description: 'Double Mining in Curve USDT(Curve aave 3pool)',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdt'],
    tokensById: ['tether'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCurveAave3Pool,
    stakeToken: '3CRV',
    stakeTokenSymbol: 'alda3CRV',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    id: 5,
    logo: '👸',
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 10,
    name: 'aldCurve 3pool',
    description: 'Double Mining in Curve USDT(Curve 3pool)',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdt'],
    tokensById: ['tether'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCurve3Pool,
    stakeToken: '3CRV',
    stakeTokenSymbol: 'ald3CRV',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    id: 6,
    logo: '👸',
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 11,
    name: 'aldCurve HBTC',
    description: 'Double Mining in Curve USDT(Curve HBTC)',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['btc'],
    tokensById: ['bitcoin'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCurveHBTC,
    stakeToken: 'CRV',
    stakeTokenSymbol: 'aldhCRV',
    stakeTokenDecimals: 6,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    id: 7,
    logo: '🧙‍♂️',
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 2,
    name: 'aldCurve SETH',
    description: 'Double Mining in Curve SETH',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['eth'],
    tokensById: ['ethereum'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCurveSETH,
    stakeToken: 'CRV',
    stakeTokenSymbol: 'aldeCRV',
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    id: 8,
    logo: '🧙‍♀️',
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 3,
    name: 'aldCurve renWBTC',
    description: 'Double Mining in Curve BTC',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['btc'],
    tokensById: ['bitcoin'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultCurveRenWBTC,
    stakeToken: 'crvRenWBTC',
    stakeTokenSymbol: 'aldcrvRenWBTC',
    stakeTokenDecimals: 8,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
  },
  {
    id: 9,
    logo: '🍣',
    pool_id: 4,
    name: 'aldSushi ETH/USDC SLP',
    description: 'Double Mining with Sushi ETH/USDC SLP',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['eth'],
    tokensById: ['ethereum'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultSushiETHUSDC,
    stakeToken: 'ETH/USDC SLP',
    stakeTokenSymbol: 'aldETH/USDC SLP',
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'sushi',
  },
  {
    id: 10,
    logo: '🍣',
    pool_id: 5,
    name: 'aldSushi ETH/DAI SLP',
    description: 'Double Mining with Sushi ETH/DAI SLP',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['eth'],
    tokensById: ['ethereum'],
    tokensPrices: [0],
    stakeTokenContractAddress: config.contracts.vaultSushiETHDai,
    stakeToken: 'ETH/Dai SLP',
    stakeTokenSymbol: 'aldETH/Dai SLP',
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'sushi',
  },
  {
    id: 11,
    logo: '🍣',
    pool_id: 6,
    name: 'aldSushi ETH/WBTC SLP',
    description: 'Double Mining with Sushi ETH/WBTC SLP',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['btc', 'eth'],
    tokensById: ['bitcoin', 'ethereum'],
    tokensPrices: [0, 0],
    stakeTokenContractAddress: config.contracts.vaultSushiETHWBTC,
    stakeToken: 'ETH/WBTC SLP',
    stakeTokenSymbol: 'aldETH/WBTC SLP',
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'sushi',
  },
  // Liquidity Mining
  {
    logo: '🦄️',
    pool_id: 5,
    name: 'Uniswap ETH/ALD LP',
    description: 'Deposit ETH/ALD UNI LP and earn ALD',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['eth', 'ald'],
    tokensById: ['ethereum', 'ald'],
    tokensPrices: [0, 0],
    stakeTokenContractAddress: config.contracts.unilpALDETH,
    stakeToken: 'ETH/ALD',
    stakeTokenSymbol: 'UNI V2 ETH/ALD',
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'uni',
  },
  {
    logo: '🦄️',
    pool_id: 6,
    name: 'Uniswap USDC/ALD LP',
    description: 'Deposit USDC/ALD UNI LP and earn ALD',
    apy: 40.32,
    basicAPY: 10.12,
    rewardAPY: 30.2,
    tvl: 0,
    type: 'staking',
    tokens: ['usdc', 'ald'],
    tokensById: ['usd-coin', 'ald'],
    tokensPrices: [0, 0],
    stakeTokenContractAddress: config.contracts.unilpALDUSDC,
    stakeToken: 'USDC/ALD',
    stakeTokenSymbol: 'UNI V2 USDC/ALD',
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'uni',
  },
]

export default pools
