const { NETWORK_ENV } = process.env

const forkAddresses = {
  tokens: {
    WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    WBTC: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    CRV: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    CVX: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
    LDO: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
    SPELL: '0x090185f2135308BaD17527004364eBcC2D37e5F6',
    mimCrv: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    renCrv: '0x49849C98ae39Fff122806C06791Fa73784FB3675',
    stethCRV: '0x06325440D014e39736583c165C2963BA99fAf14E',
    '3CrvCrypto2': '0xc4AD29ba4B3c580e6D59105FFf484999997675Ff',
    '3poolCrv': '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
  },
  ald: '0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
  xald: '0x272bFF6ff60a2D614e654ba06AC4A7422630AdE3',
  wxald: '0xb13B85363A25c7361877EebaEcCed99e353F2aF9',
  treasury: '0xBDC423927e70E4013A7906FE54ad8209643f734C',
  oracles: {
    chainlink: '0x71E60b439c533b09cB23dd2BCd439A8c337A5C97',
    uniswapETH: '0x46Be096eC3F3b51DfFC1B694789D2c6765f3BD29',
    uniswapPair: '0xfB90E4d1BafAb5EA30D3ABd0eEf0B89948a17fe3',
  },
  rewardBond: '0xd3D8478C5e9d1ac26a6dc55381f58Fa4B587D97d',
  directBond: '0x8aE2a7E0C8627d6FA476aA3F89E1804dAfd2b7dD',
  staking: '0x55047ea4d6799262bBD9E7CDc37027F13c513516',
  distributor: '0xAd2362E96C4a2Aa71e717Ef408D90b52e5de4de0',
  keeper: '0x3cE40A7a41E8A1941D79F1e5661560e12C4E2535',
  airdrop: '0xE7a15d40a91Ac1fF3Cc6CA1DfE0e95471E5CA4d8',
  vaults: {
    convex: {
      mim: '0x6787Db5223B84753AC597431E9137221C39DA212',
      ren: '0x24b724aae64ccAB170fa05624A400215c59dB697',
      steth: '0x8D1631C549f4b08c4C72a874a69764AB56f7B4EA',
      tricrypto: '0x5f01D42Ac4529f79E7107138372Fea91D3f28cF1',
      tripool: '0x6A975BB2b977361e53d37407CCa3e035528c14D8',
    },
  },
  aladdinHonor: '0xD026A86997391c7f311Cd657882d9Df745e055B6',
  lottery: '0x317e8FA093CD924b65c320700570516b4C1A5E02',
}

// Configs related to netwwork

// @ts-ignore
let addrConfig = {
  tokens: {
    WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    WBTC: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    CRV: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    CVX: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
    LDO: '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
    SPELL: '0x090185f2135308BaD17527004364eBcC2D37e5F6',
    mimCrv: '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    renCrv: '0x49849C98ae39Fff122806C06791Fa73784FB3675',
    stethCRV: '0x06325440D014e39736583c165C2963BA99fAf14E',
    '3CrvCrypto2': '0xc4AD29ba4B3c580e6D59105FFf484999997675Ff',
    '3poolCrv': '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
  },
  dao: '0x272bFF6ff60a2D614e654ba06AC4A7422630AdE3',
  ald: '0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
  xald: '0xb13B85363A25c7361877EebaEcCed99e353F2aF9',
  wxald: '0xBDC423927e70E4013A7906FE54ad8209643f734C',
  treasury: '0x5aa403275cdf5a487D195E8306FD0628D4F5747B',
  oracles: {
    chainlink: '0x7f751E35AFe72775Ec88e74386BbC9b68214153e',
    uniswapETH: '0x1CD632E48BeBbdA94EA0431fb8979C3012E186e9',
    uniswapPair: '0x41718d90B2889be621f17a7f7801aa1BBd9C6840',
  },
  rewardBond: '0xc6a477f1ef7B0Ac7530B6B78f52e270A973B0198',
  directBond: '0x8aE2a7E0C8627d6FA476aA3F89E1804dAfd2b7dD',
  staking: '0x71072Bd71Cc4f83154F1f77b4bD5E2D71BD6aa2c',
  distributor: '0x1cCa80c17e9155eB1F5a1Df52Ef92Cc551A4b816',
  keeper: '0xDC2673d6B09a022de00fFE16bf1aE7F8004a3230',
  airdrop: '0x4c42A7C2Bb34e2b9dC43098B6874771e2116e940',
  vaults: {
    convex: {
      mim: '0x6787Db5223B84753AC597431E9137221C39DA212',
      ren: '0x24b724aae64ccAB170fa05624A400215c59dB697',
      steth: '0x8D1631C549f4b08c4C72a874a69764AB56f7B4EA',
      tricrypto: '0x5f01D42Ac4529f79E7107138372Fea91D3f28cF1',
      tripool: '0x6A975BB2b977361e53d37407CCa3e035528c14D8',
    },
  },
  aladdinHonor: '0x433f078C8C00593cD76359180441Bd4Ad6D6B6c5',
}

// switch (NETWORK_ENV) {
//   case 'mainnet':
//     addrConfig =
//     break
//   default:
//     addrConfig = forkAddresses
//     break
// }

export default {
  ...addrConfig,
}
