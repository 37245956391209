import localeAntd from 'antd/es/locale/zh_CN'

const messages = {
  // General
  'common.connectWallet': '连接钱包',
  'common.yourBalance': '您的余额',
  'common.balance': '余额',
  'common.step': '步骤',
  'common.rewardsHint1': '您有 ',
  'common.rewardsHint2': '质押奖励',
  'common.warning': '警告',
  'common.guide': '引导',
  'common.applyNow': '现在申请',
  'common.aldPrice': 'ALD 价格',
  'common.all': '全部',
  'common.myPools': '我锁仓的',
  'common.bond': 'Bond',
  'common.myBonds': 'My Bonds',
  'common.myPoolsVault': '我存入的',
  'common.earned': '已赚取',
  'common.reward': '收益',
  'common.staked': '已质押',
  'common.deposited': '已存入',
  'common.readMore': '了解更多',
  'common.learnMore': '了解更多',
  'common.common': '步骤',
  'common.rewardHint': 'ALD 奖励即将释放',
  'common.days': '天',
  'common.hours': '小时',
  'common.minutes': '分钟',
  'common.seconds': '秒',
  'common.detail': '详情',
  'common.congratulation': '祝贺',
  'common.claimed': '领取了',
  'common.shareWith': '分享给朋友',
  // Action
  'action.close': '关闭',
  'action.back': '返回',
  'action.join': '加入 Aladdin DAO',
  'action.deposit': '存入',
  'action.redeem': '赎回',
  'action.withdraw': '取出',
  'action.claim': '领取',
  'action.claimAll': '领取所有',
  'action.unstake': '取出',
  'action.stake': '质押',
  'action.wrapAndStake': 'Wrap&Stake',
  'action.claimAndUnstake': 'Claim&Unstake',
  'action.staking': '质押中',
  'action.unwrap': 'Unwrap',
  'action.farm': 'Farm',
  'action.harvest': 'Harvest',
  'action.strategy.harvest': 'strategy harvest',
  'action.unstakeClaim': '取出（含奖励）',
  'action.approve': '授权',
  'action.approving': '授权中',
  'action.addLiquidity': '增加流动性',
  'action.stakeLp': '质押 LP',
  'action.logout': '登出',
  'action.quit': '退出',
  'action.notQuit': '不退出',
  'action.ok': '确定',
  'action.got': '好的',
  'action.view': '查看',
  'action.save': '保存',
  'action.cancel': '取消',

  // Form
  'form.max': '全部',
  'form.amount': '金额',
  'form.shareToPurchase': '最多购买份额',
  'form.wantToGet': '我希望得到',
  'form.output': '产出',
  'form.isWhitelisted': '是否被列入白名单',
  'form.shareCap': '您的额度/额度上限',
  'form.accumulatedShares': '累积额度',
  'form.alddaoCap': 'ALDDAO 的上限',
  'form.alddaoInTotal': '总计 ALDDAO',
  'form.alddaoInTotalHint': '购买的 ALDDAO 总量',
  'form.aldVoteBalance': 'ALDVOTE 余额',
  'form.estimateOutput': '将会收到',
  'form.currentlyStaked': '目前已质押',
  'form.rewardsAvailable': '可领取的奖励',
  'form.performanceFeeHint': `资产由策略自动管理，收益从 0:00 (UTC) 开始每 8 小时更新一次。收益的代币为 { rewardToken }  ，之后 Aladdin 将更新对 Vaults 收益的资产管理机制。`,
  'form.performanceFee': '手续费',
  'form.performanceFeeText': '收益的10%',
  // Table
  'table.tvl': 'TVL（总锁仓量）',
  'table.vault_token_symbol': 'Vault 通证符号',
  'table.vault_token_decimal': 'Vault 通证精度',
  'table.vault_contract_address': 'Vault 合约地址',
  'table.stake_contract_symbol': '质押通证符号',
  'table.stake_contract_address': '质押合约地址',
  'table.tokenPrice': '通证价格',
  'table.apy': 'APY（年化收益）',
  'table.liquidity': '流动性',
  'table.reward': '奖励',
  'table.pool': '池子',
  'table.rewardToken': '奖励通证',
  'table.targetTvl': '目标 TVL',
  'table.currentTvl': '当前的 TVL',
  'table.kpiRewards': 'KPI 和奖励',
  'table.miners': '矿工',
  'table.dao': 'DAO 成员',
  'table.dueDate': '到期日',
  'table.tvlRatio': 'TVL 比率',
  'table.minersRewards': '矿工奖励',
  'table.myReward': '我的奖励',
  'table.vault_go_to_stake': '去质押',

  // Navigation
  'nav.home': '首页',
  'nav.vaults': 'Vaults',
  'nav.earn': 'Earn',
  'nav.bond': 'Bond',
  'nav.stake': 'Stake',
  'nav.rebaseAndStake': 'Rebase & Stake',
  'nav.staking': '质押',
  'nav.mining': '挖矿',
  'nav.membership': 'Dao 成员',
  'nav.optionReward': '期权奖励',
  'nav.lootMinings': 'Loot 挖矿',
  'nav.genesis': 'Genesis',
  'nav.mysterySchool': '神秘学院',
  'nav.airdrop': '空投',

  // home UI
  'home.subtitle': '跟随神灯在 Defi 世界中获得成长',
  'home.desc': '像阿拉丁一样，在我们的神灯精灵的帮助下寻求财富，避免在 Defi 的危险道路上遭受伤害',
  'home.marketCap': '市值',
  'home.stakedRatio': '锁仓率',
  'home.nextRebase': '下次 Rebase',
  'home.bond.desc': '以折扣价获得 ALD 帮助 Aladdin 国库增长，以此为神灯增加燃料',
  'home.bond.action1': '存入资产以赚取 ALD - 最简单的参与方式！',
  'home.bond.action2': '抵押 Vault 资产，自动获得 ALD 奖励',
  'home.stake.desc': '锁仓你的 ALD 是简单模式的玩法。由 Defi 高手处理棘手的事情，你只需要跟随，就可以获得不断增长的收益份额',
  'home.stake.action1': '锁仓 ALD 获得 xALD 以赚取 Rebase 收益',
  'home.mystery.desc':
    '对于不甘于简单投资，想在 Defi 中学习更多的人。可以在神秘学院入门，在 Boule 会议上与 Defi 精英们一同成长，提升自己。这不适合胆小的人',

  // Bond UI
  'bond.title.GBond': 'Bond',
  'bond.desc.GBond': 'Get discounted ALD while helping to grow Aladdin’s treasury, adding fuel to Aladdin’s lamp.',
  'bond.title.GStaking': 'Genesis Staking',
  'bond.desc.GStaking': 'Get discounted ALD while helping to grow Aladdin’s treasury, adding fuel to Aladdin’s lamp.',
  'bond.subtitle': '以折扣价获得 ALD 帮助 Aladdin 国库增长，以此为神灯增加燃料',
  'bond.bondPrice': 'Bond 价格',
  'bond.staticROI': '静态 ROI',
  'bond.marketPrice': '市场价',
  'bond.vestingTerm': '释放期限',
  'bond.discount': '折扣',
  'bond.willBeVested': '查看释放情况',
  'bond.viewInStake': '去 Stake 查看',
  'bond.howMuchBond': '你想要 Bond 多少?',
  'bond.howMuchStake': '你想要 Stake 多少?',
  'bond.amount': '数量',
  'bond.balance': '余额',
  'bond.purchased': '已购',
  'bond.price': '价格',
  'bond.max': '最大',
  'bond.getMore': '获取更多',
  'bond.slippageTolerance': '滑点',
  'bond.slippageHint': '如果价格浮动超过此百分比，你的交易将不会成功',
  'bond.auto': '自动',
  'bond.youWillReceive': '你将会收到',
  'bond.receiveMin': '至少收到',
  'bond.averageFilled': '预估平均成交价格',

  // stake
  'stake.subtitle': '锁仓你的 ALD 是简单模式的玩法。由 Defi 高手处理棘手的事情，你只需要跟随，就可以获得不断增长的收益份额',
  'stake.totalSupply': 'ALD 总供应量',
  'stake.totalStaked': 'ALD 总锁仓数',
  'stake.stakedRate': 'ALD 总锁仓率',
  'stake.aldPrice': 'ALD 价格',
  'stake.totalStakedBalance': '我的总锁仓数',
  'stake.claimable': '可领取的',
  'stake.stake': '锁仓',
  'stake.unlockedLocked': '已解锁/锁定',
  'stake.fullyVested': '释放周期',
  'stake.fromVaults': '来自 Vaults',
  'stake.releaseTime': '每天获得的收益将在 5 天内释放',
  'stake.fromBond': '来自 Bond',
  'stake.howMuchStake': '你想锁仓多少？',
  'stake.amount': '数量',
  'stake.balance': '余额',
  'stake.price': '价格',
  'stake.getAld': '获得 ALD',
  'stake.youWillReceive': '你将会收到',
  'stake.youCanClaim': '你可以领取 ',
  'stake.stakedDetail': 'Staked 详情',

  // rebase
  'rebase.subtitle': '持有 xALD 每天都会获得 rebase 奖励，只要持有即可！',
  'rebase.currentIndex': '当前指数',
  'rebase.nextRebases': '下次 Rebases',
  'rebase.myBalance': '我的 xALD 余额',
  'rebase.walletBalance': '钱包内余额',
  'rebase.stakedBalance': '质押的余额',
  'rebase.nextAmount': '下次奖励数量',
  'rebase.nextYield': '下次奖励收益率',
  'rebase.action.title': '赎回 xALD',
  'rebase.available': '可用',
  'rebase.youWillReceive': '你将会收到',
  'rebase.unlocked': 'Unlocked',

  // mining UI
  'mining.banner': '与 DeFi 高手一同挖矿',
  'mining.all': '全部',
  'mining.vault': '耕种',
  'mining.staking': '质押',
  'mining.liquidity': '流动性挖矿',
  'membership.title': '委员会成员',
  'membership.subtitle':
    'AladdinDAO 创世成员通过存入 USDC，可以获得份额通证（ALDDAO）和提案权通证（ALDVOTE），ALDDAO Stake 后获得 ALD 通证。ALDVOTE 不能转让，只用于 DAO 的治理决策。合约地址：',
  'membership.limitHint': '目前仅允许白名单地址进行存款。请联系我们的社区管理员。',
  'membership.clickHere': '点击这里',
  'membership.whitelistAddress': '白名单地址',
  'membership.howTo': '加入我们',
  'membership.familyPurpose': '我们的目标',
  'membership.familyMembers': '我们的成员',

  // vaults UI
  'vaults.vaultsTVL': 'Vaults TVL',
  'vaults.subtitle': '存入 Defi 资产赚取 xALD',
  'vaults.howMuchDeposit': '你想要存入多少？',
  'vaults.howMuchWithdraw': '你想要取出多少？',
  'vaults.vestingTerm': '收益释放周期',
  'vaults.earned': '收益',
  'vaults.unlocked': '解锁的',
  'vaults.locked': '锁定的',
  'vaults.releaseTime': '每天获得的收益将在 5 天内释放',
  'vaults.available': '可用',
  'vaults.youCan': '你可以在',
  'vaults.viewInStake': 'Stake 中查看',

  // for bond prize
  'bondDraw.subtitle': 'Honor NFT 的幸运抽奖',
  'bondDraw.newRound': '新一轮',
  'bondDraw.upcoming': '即将到来',
  'bondDraw.draw': '抽奖',
  'bondDraw.firstPrize': '一等奖',
  'bondDraw.oneWinner': '一个幸运者',
  'bondDraw.secondPrize': '二等奖',
  'bondDraw.twoWinners': '两个幸运者',
  'bondDraw.thirdPrize': '三等奖',
  'bondDraw.threeWinners': '三个幸运者',
  'bondDraw.luckyPrize': '幸运奖',
  'bondDraw.divide': '瓜分',
  'bondDraw.100Winners': '最多 100 个幸运者',
  'bondDraw.totalDrawTimes': '总抽奖次数',
  'bondDraw.totalDrawPrize': '总抽奖金额',
  'bondDraw.desc1': '债券销售额 10% 等值数额的 ALD 放入奖池，当奖池达到 1,000,000 ALD 时开奖 ',
  'bondDraw.desc2': '一轮接着一轮',
  'bondDraw.desc3': 'NFT 越多，荣誉分数越高。同种 NFT 只计算一次分数，每个 NFT 可参与 10 次抽奖',
  'bondDraw.ownedNFT': '拥有的 Honor NFT',
  'bondDraw.myScore': '我的荣耀分',
  'bondDraw.myRate': '我的中奖励',
  'bondDraw.myPrize': '我的奖金',
  'bondDraw.myRecord': '我的中奖记录',
  'bondDraw.nftDetails': 'NFT 明细',
  'bondDraw.nftName': 'NFT 名称',
  'bondDraw.score': '分数',
  'bondDraw.availableTimes': '可用次数',

  'bondNFT.subtitle': 'Bond 荣耀徽章',
  'bondNFT.myBonds': '我的 Bonds',
  'bondNFT.owned': '已拥有',
  'bondNFT.claimable': '可领取',

  // mystery school
  'mystery.title': '神秘学院',
  'mystery.subtitle': '神秘学院是 AladdinDAO 的参与者获得额外 ALD 奖励的地方，这里的奖励是由社区创建和赚取',

  // staking UI
  'staking.title': '质押 Vault 凭证通证',
  'staking.subtitle': '质押 Vault 凭证通证获得 AladdinDAO 治理通证',
  'staking.stakingTVL': '质押 TVL',
  'staking.availableToClaim': '可领取的 ALD',
  'staking.aldTotalSupply': 'ALD 总供应量',
  'staking.aldBonusPerBlock': '每个区块的挖矿奖励',

  // staking hint
  'stakingHint.getMore': '获取',
  'stakingHint.goLiquidityPool': '去查看',
  'stakingHint.goEarn': '去赚取 ALD',
  'stakingHint.aldStaking': '获得 Aladdin 质押',
  'stakingHint.learnMore': '了解更多 AladdinDAO',
  'stakingHint.whenStake': 'AladdinDAO 拥有独创的治理和激励机制',
  'stakingHint.stakeEarn': '质押你的 {vaultTokenSymbol} 去赚取 ALD',
  'stakingHint.getRequiredDeposit': '点击链接获取 {tokenSymbol}',
  'stakingHint.getRequiredStaking': '点击链接获取 {tokenSymbol}',

  // boule plus
  'boule.title.quit': '退出 Boule Plus',
  'boule.unlocking': '解锁',
  'boule.unlockTime': '将会在此时间解锁：',
  'boule.after.unlocking': '解锁后, 你可以提取你的 ALD',
  'boule.claimable': '可提取',
  'boule.bannerTitle': 'Boule Plus',
  'boule.bannerSubtitle': '由群体智慧支持的无许可的一站式流动性挖矿平台',
  'boule.bannerSubtitle.1': '通过参与投票选出表现优异的矿池，社区成员可以获得额外的 ALD。 我们称这种模式为 Vote-to-Earn',
  'boule.what.title': 'Aladdin Boule Plus',
  'boule.what.1': '通过参与投票获得链上声誉',
  'boule.what.2': '成为 Boule Member 的快速通道',
  'boule.what.3': 'Big Brain 会议优先参与权',
  'boule.what.4': 'Vote-to-Earn, ALD 持有者通过投票最大化收益',
  'boule.step.1': '锁仓 {token} 获得 ALDPLUS',
  'boule.step.2': '对流动性挖矿项目投票，每两周获得一次奖励',
  'boule.step.3.title': '赚取方式',
  'boule.step.3': '对表现优异的 Vault 投赞成票',
  'boule.step.3.1': '对表现不佳的 Vault 投反对票',
  'boule.step.4.title': '额外奖励',
  'boule.step.4': '持有 ALDPLUS 超过 3 个月即可获得专属 NFT',
  'boule.date': '第一年',
  'boule.rewardTitle': '投票总收益',
  'boule.youAre.title': '你是 Boule Plus 成员',
  'boule.youAre.1': '每个月有 4 枚选票',
  'boule.youAre.2': '努力研究并及时投票',
  'boule.votingEntrance': '投票',
  'boule.hold': '持有 ALDPLUS 才能持续获得收益',
  'boule.unstakeAndLose': '解锁并放弃奖励',
  'boule.become.title': '成为 Boule Plus 成员',
  'boule.become.hint1': '你需要锁仓 ',
  'boule.become.hint2': ' 才能成为 Boule Plus 成员',
  'boule.clickToStake': '质押',
  'boule.stake.hint1': '你将会收到 ALDPLUS 作为凭证',
  'boule.stake.hint2': '你可以随时解锁 ALD 并退出 Boule Plus，需等待 14 天的锁定期后，可以领取你的 ALD',
  'boule.stake.hint3': '',
  'confirmUnstake.title': '确定解锁吗？',
  'confirmUnstake.desc': '解锁并退出Boule Plus，您将不能继续获得投票带来的收益',
  'confirmUnstake.tip': '在 {days} 以后, 可以领取你的 ALD',
  'notQualified.title': '成为 Boule Plus 成员',
  'notQualified.desc.1': '你需要先通过小测验',
  'notQualified.desc.2': '如果已经通过了，请确定地址是正确的',
  // boule member
  'bouleMember.bannerTitle': 'Boule Member',
  'bouleMember.bannerSubtitle': 'Boule Members are top tier experts in DeFi. They identify the most promising DeFi projects.',
  'bouleMember.bannerData1': 'Total Profits',
  'bouleMember.bannerData2': 'Voting Boule Members',
  'bouleMember.bannerData3': 'Voted Projects',
  'bouleMember.bannerData4': 'ALD Rewards/Epoch',
  'bouleMember.moreInfo.title': 'More information',
  'bouleMember.lastEpochContributionValue': 'Last Epoch Contribution Value',
  'bouleMember.bouleMembers': 'Boule Members',
  'bouleMember.projects': 'Projects',
  'bouleMember.myReferral': 'My Referral',
  'bouleMember.totalCV': 'Total CV',
  'bouleMember.totalRewards': 'Total Rewards',
  'bouleMember.project': 'Project',
  'bouleMember.totalProfits': 'Total Profits',
  'bouleMember.lastEpochTVL': 'Last Epoch TVL',
  'bouleMember.thisEpochProfit': 'This Epoch Profit',
  'bouleMember.supportedVotes': 'Supported Votes',
  'bouleMember.daysSinceListed': 'Days since listed',
  'bouleMember.decayRate': 'Decay Rate',
  'bouleMember.averageValueOfAdjestedProfits': 'Average value of adjested profits',
  'bouleMember.adjustedProfitsOfStrategyDeveloper': 'Adjusted profits of strategy developer',
  'bouleMember.conteibutionRatloOfStrategyDeveloper': 'Conteibution ratlo of strategy developer',
  'bouleMember.strategyDeveloperRewards': 'Strategy developer rewards(ALD)',
  'bouleMember.totalContributionValue': 'Total Contribution Value',
  'bouleMember.votedBouleMember': 'Voted Boule Member',
  'bouleMember.myProfile': 'My Profile',
  'bouleMember.contributionValue': 'Contribution Value',
  'bouleMember.ratio': 'Ratio',
  'bouleMember.rewards': 'Rewards',
  'bouleMember.talentHunterRewards': 'Talent Hunter Rewards',
  'bouleMember.relatedProjectPerformance': 'Related Project Performance(this epoch)',
  'bouleMember.proposal': 'Proposal',
  'bouleMember.voteWeightOfThisBouleMember': 'Vote Weight of this Boule Member',
  'bouleMember.relaticeProfits': 'Relatice Profits',
  'bouleMember.proportionInContributionValue': 'Proportion in Contribution Value',
  // option reward UI
  'optionReward.title': '奖励',
  'optionReward.subtitle':
    'Aladdin 将 15% 的通证用于激励 TVL 增长，TVL 每增加 1 亿美金，奖励释放 0.75% 的 ALD，最大奖励到 20 亿美金 TVL，激励有效期一个月，从创世挖矿开始，一个月后将不在释放奖励。奖励将分发给所有质押 ALD 的用户，一年内匀速释放完成。 质押 ALD 的用户，有机会获得平台上架项目 TVL 增长带来的期权奖励。',
  'optionReward.aladdinStaking': 'Aladdin 质押',

  // staking reward UI
  'stakingReward.title': 'Aladdin 挖矿',

  'stakingReward.subtitle': '通证持有者可以将 ALD 质押到 Aladdin 奖励池中，并在达到目标 TVL 后从项目奖励中获取奖励。',

  'stakingReward.tableInfo': '',

  // vault UI
  'vault.tableInfo': '有关此 Vault 更多的信息',
  'staking.tableInfo': '有关质押池更多的信息',

  // liquidity UI
  'liquidity.title': '流动性',
  'liquidity.subtitle': '流动性描述',
  'liquidity.tableInfo': '关于项目',

  // banners
  vaultBanner1: '实现双挖收益 : {rewardSymbol}+ALD。',
  vaultBanner2: `第一步：在 {poolName} 平台中存入 {deposit}，获得 {stakeSymbol}。`,
  vaultBanner3: `第二步：将 {stakeSymbol} 存入 Aladdin 的 Vault 中，赚取 {rewardSymbol} 收益，并获得 {vaultSymbol} 作为 Vault 凭证。`,
  vaultBanner4: `第三步：将 {vaultSymbol} 存入 Aladdin 的质押池中，可获得额外的 ALD 通证奖励。`,
  stakingBanner: `质押 Vault 凭证通证，在 Vault 中获得收益的同时，还可以获得额外的 ALD 收益。如果没有 {stakeTokenSymbol}, 请先到 Vault 中存入 {stakeToken}`,
  stakingBannerUni: `在 Uniswap 中获得 {stakeTokenSymbol} ，质押到 Aladdin 中可以获得额外的 ALD 收益`,

  // vaultBanner1: '在 {poolName} 平台中存入 {deposit}，获得 {stakeSymbol}。',
  // vaultBanner2: `用户将 {stakeSymbol} 存入 Aladdin 的 Vault 中，Aladdin 协议会自动把 {stakeSymbol} 存入 {poolName} 平台，赚取 {rewardSymbol} 和利息，同时用户还可获得 {vaultSymbol}。`,
  // vaultBanner3: `将 {vaultSymbol} 存入 Aladdin 的质押池中，可获得 ALD 通证奖励。`,
  // vaultBanner4: `双挖收益 : {rewardSymbol}+ALD。`,
  // stakingBanner: `Vault 中的资产（例如Comp,CRV,Sushi等）存入 StakingPool 后，在 Vault 中获得原资产收益的同时，还可以获得 ALD 收益。如果没有 {stakeTokenSymbol}, 请先到 Vault 中存入{stakeToken}`,

  // lbp pagge
  'lbp.title': 'Alddin Token 首次启动',
  'lbp.subtitle': '北京时间：2021 年 4 月 XX 日 XX:00:00-XX:00:00 - 2021 年 4 月 XX 日 XX:00:00-XX:00:00)',
  'lbp.banner1': '交易对和初始金额',
  'lbp.banner2': '起始比率（ALD：USDC）',
  'lbp.banner3': '结束比率（ALD：USDC）',
  'lbp.banner4': '起始价',
  'lbp.aldBalance': 'ALD 余额',
  'lbp.usdcBalance': 'USDC 余额',
  'lbp.aldWeight': 'ALD 权重',
  'lbp.usdcWeight': 'USDC 权重',
  'lbp.aldSold': 'ALD 已售',
  'lbp.fundRaised': '已募集资金',
  'lbp.spotPrice': '现货价格',
  'lbp.terminal': '初始定价',
  'lbp.swapOnBalance': 'Swap On Balancer',
  'lbp.warning1': '请不要急于购买，否则您将被拒收。',
  'lbp.warning2': '注意您的 Gas 手续费和交易滑点设置。',
  'lbp.warning3': '在 LBP 结束之前，请勿在 Uniswap 或 Balancer 上创建新池（请注意避免永久损失！）。',
  'lbp.warning4': '提防欺诈，仅使用 Alddin 官方公布的 ALD 地址。',

  'countdown.estimateDate': '预估释放时间',
  'countdown.rewardHint1': 'ALD 奖励将在',
  'countdown.rewardHint2': '区块释放',
}

export default {
  locale: 'zh-CN',
  localeAntd,
  messages,
}
