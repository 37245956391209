import erc20ABI from './abi/ERC20.json'
import multiCallABI from './abi/MultiCall.json'
import vaultABI from './abi/Vault.json'
import tokenMasterABI from './abi/TokenMaster.json'
import daoFundingABI from './abi/DAOFunding.json'
import daoTreasuryABI from './abi/DAOTreasury.json'
import strategyABI from './abi/StrategyUSDCCompound.json'
import aldPlusABI from './abi/ALDPlus.json'

import multiStakingRewards from './abi/MultiStakingRewards.json'

import balancerLBPABI from './abi/balancerLBP.json'

import curve3poolswapABI from './abi/curve3poolswap.json'
import curveRenBTCSwapABI from './abi/curveRenBTCSwap.json'

export default {
  erc20ABI,
  multiCallABI,
  vaultABI,
  tokenMasterABI,
  daoFundingABI,
  daoTreasuryABI,
  multiStakingRewards,
  strategyABI,
  curve3poolswapABI,
  balancerLBPABI,
  curveRenBTCSwapABI,
  aldPlusABI,
}
