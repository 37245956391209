import localeAntd from 'antd/es/locale/en_US'

const messages = {
  // General
  'common.connectWallet': 'Connect Wallet',
  'common.yourBalance': 'Your Balance',
  'common.balance': 'Balance',
  'common.step': 'Step',
  'common.rewardsHint1': 'You have ',
  'common.rewardsHint2': ' Staking rewards',
  'common.warning': 'Warning',
  'common.guide': 'GUIDE',
  'common.applyNow': 'Apply Now',
  'common.aldPrice': 'ALD Price',
  'common.all': 'All',
  'common.myPools': 'My Pools',
  'common.bond': 'Bond',
  'common.myBonds': 'My Bonds',
  'common.myPoolsVault': 'My Pools',
  'common.earned': 'Earned',
  'common.reward': 'REWARD',
  'common.staked': 'Staked',
  'common.deposited': 'Deposited',
  'common.readMore': 'Read More',
  'common.learnMore': 'Learn More',
  'common.common': 'Step',
  'common.rewardHint': 'ALD rewards will be released',
  'common.days': 'Days',
  'common.hours': 'Hours',
  'common.minutes': 'Minutes',
  'common.seconds': 'Seconds',
  'common.detail': 'Detail',
  'common.congratulation': 'Congratulation',
  'common.claimed': 'Claimed',
  'common.shareWith': 'Share with friends',
  // Action
  'action.close': 'Close',
  'action.back': 'Back',
  'action.join': 'Join Aladdin DAO',
  'action.deposit': 'Deposit',
  'action.withdraw': 'Withdraw',
  'action.redeem': 'Redeem',
  'action.claim': 'Claim',
  'action.claimAll': 'Claim All',
  'action.unstake': 'Unstake',
  'action.stake': 'Stake',
  'action.wrapAndStake': 'Wrap & Stake',
  'action.claimAndUnstake': 'Claim & Unstake',
  'action.staking': 'Staking',
  'action.unwrap': 'Unwrap',
  'action.farm': 'Farm',
  'action.harvest': 'Harvest',
  'action.strategy.harvest': 'Harvest Strategy',
  'action.unstakeClaim': 'Unstake & Claim',
  'action.approve': 'Approve',
  'action.approving': 'Approving',
  'action.addLiquidity': 'Add Liquidity',
  'action.stakeLp': 'Stake LP',
  'action.logout': 'Disconnect',
  'action.quit': 'Confirm',
  'action.notQuit': 'Cancel',
  'action.ok': 'OK',
  'action.got': 'OK',
  'action.view': 'View',
  'action.save': 'Save',
  'action.cancel': 'Cancel',
  // Form
  'form.max': 'MAX',
  'form.amount': 'Amount',
  'form.shareToPurchase': 'Share',
  'form.wantToGet': 'I want to get',
  'form.output': 'Output',
  'form.isWhitelisted': 'Is Whitelisted',
  'form.shareCap': 'Your Share / Share Cap',
  'form.accumulatedShares': 'Accumulated Shares',
  'form.alddaoCap': 'ALDDAO CAP',
  'form.alddaoInTotal': 'ALDDAO In Total',
  'form.alddaoInTotalHint': 'Amount of ALDDAO bought',
  'form.aldVoteBalance': 'ALDVOTE Balance',
  'form.estimateOutput': 'Estimated Output',
  'form.currentlyStaked': 'Currently Staked',
  'form.rewardsAvailable': 'Rewards Available',
  'form.performanceFeeHint': `Assets are automatically managed by the strategy, and the reward will be updated every 8 hours from 0:00 (UTC). Farmers will get { rewardToken }  as rewards. Aladdin will update the asset management mechanism for Vaults rewards in the future.`,
  'form.performanceFee': 'Fee',
  'form.performanceFeeText': '10% of Rewards',
  // Table
  'table.tvl': 'TVL(Total Value Locked)',
  'table.vault_token_symbol': 'Vault Token Symbol',
  'table.vault_token_decimal': 'Vault Token Decimal',
  'table.vault_contract_address': 'Vault Contract Address',
  'table.stake_contract_symbol': 'Stake Token Symbol',
  'table.stake_contract_address': 'Stake Token Address',
  'table.tokenPrice': 'Token Price',
  'table.apy': 'APY(Annual Percentage Yield)',
  'table.liquidity': 'Liquidity',
  'table.reward': 'Reward',
  'table.pool': 'Pool',
  'table.rewardToken': 'RewardToken',
  'table.targetTvl': 'Target TVL',
  'table.currentTvl': 'Current TVL',
  'table.kpiRewards': 'KPI & Rewards',
  'table.miners': 'Miners',
  'table.dao': 'DAO',
  'table.dueDate': 'Due Date',
  'table.tvlRatio': 'TVL-Ratio',
  'table.minersRewards': "Miners' Rewards",
  'table.myReward': 'My Reward',
  'table.vault_go_to_stake': 'Stake',

  // Nav
  'nav.home': 'Home',
  'nav.vaults': 'Vaults',
  'nav.earn': 'Earn',
  'nav.bond': 'Bond',
  'nav.stake': 'Stake',
  'nav.rebaseAndStake': 'Rebase & Stake',
  'nav.staking': 'Staking',
  'nav.membership': 'DAO',
  'nav.optionReward': 'Option Rewards',
  'nav.lootMinings': 'Loot Mining',
  'nav.genesis': 'Genesis',
  'nav.mysterySchool': 'Mystery School',
  'nav.airdrop': 'Airdrop',

  // home UI
  'home.subtitle': 'Follow the light to stake, earn, learn and grow',
  'home.desc':
    'Strive like Aladdin, avoid ruin in the dangerous streets of DeFi and seek your fortune with a little help from our Genie-uses.',
  'home.marketCap': 'Market Cap',
  'home.stakedRatio': 'Staked Ratio',
  'home.nextRebase': 'Next Rebase',
  'home.bond.desc': 'Get discounted ALD while helping to grow Aladdin’s treasury, adding fuel to Aladdin’s lamp.',
  'home.bond.action1': 'Deposit assets to earn ALD - the easiest way to play!',
  'home.bond.action2': 'Stake Partner vault tokens to bond ALD automatically.',
  'home.stake.desc':
    'Play along on Easy Mode by staking your ALD! Capture your share of a growing pie and let the Big Brains handle the tough stuff.',
  'home.stake.action1': 'Stake your ALD of xALD to earn rebasing rewards.',
  'home.mystery.desc':
    'For those unsatisfied by shallow understanding and unwilling to follow the crowd. Become an Initiate in the Mystery School, and discover the secrets of the Boule Council. Learn and grow with your fellow initiates, and ascend to your rightful spot among the elite of DeFi. Not for the faint of heart.',

  // Bond UI
  'bond.title.GBond': 'Bond',
  'bond.desc.GBond': 'Get discounted ALD while helping to grow Aladdin’s treasury, adding fuel to Aladdin’s lamp.',
  'bond.title.GStaking': 'Genesis Staking',
  'bond.desc.GStaking': 'Get discounted ALD while helping to grow Aladdin’s treasury, adding fuel to Aladdin’s lamp.',
  'bond.subtitle': 'Get discounted ALD while helping to grow Aladdin’s treasury, adding fuel to Aladdin’s lamp.',
  'bond.bondPrice': 'Bond Price',
  'bond.staticROI': 'Static ROI',
  'bond.marketPrice': 'Market Price',
  'bond.vestingTerm': 'Vesting Term',
  'bond.discount': 'Discount',
  'bond.willBeVested': 'Will be vested',
  'bond.viewInStake': 'view in stake',
  'bond.howMuchBond': 'How much do you want to bond?',
  'bond.howMuchStake': 'How much do you want to stake?',
  'bond.amount': 'Amount',
  'bond.balance': 'Balance',
  'bond.price': 'Price',
  'bond.purchased': 'Purchased',
  'bond.max': 'Max',
  'bond.getMore': 'Get More',
  'bond.slippageTolerance': 'Slippage tolerance',
  'bond.slippageHint': 'Your transaction will revert if the price changes unfavorably bu more than this percentage.',
  'bond.auto': 'Auto',
  'bond.youWillReceive': 'You will receive',
  'bond.receiveMin': 'You will receive min amount',
  'bond.averageFilled': 'Est. Average filled price',

  // stake
  'stake.subtitle':
    'Play along on Easy Mode by staking your ALD! Capture your share of a growing pie and let the Big Brains handle the tough stuff.',
  'stake.totalSupply': 'Total Supply',
  'stake.totalStaked': 'Total Staked',
  'stake.stakedRate': 'Staked Ratio',
  'stake.aldPrice': 'ALD Price',
  'stake.totalStakedBalance': 'Total Staked Balance',
  'stake.claimable': 'Claimable',
  'stake.stake': 'Stake',
  'stake.unlockedLocked': 'Unlocked/Locked',
  'stake.fullyVested': 'Fully Vested',
  'stake.fromVaults': 'From Vaults',
  'stake.releaseTime': 'Daily earning will be released in 5 days',
  'stake.fromBond': 'From Bond',
  'stake.howMuchStake': 'How much do you want to stake?',
  'stake.amount': 'Amount',
  'stake.balance': 'Balance',
  'stake.price': 'Price',
  'stake.getAld': 'Get ALD',
  'stake.youWillReceive': 'You will receive',
  'stake.youCanClaim': 'You can claim ',
  'stake.stakedDetail': 'Staked Detail',

  // rebase
  'rebase.subtitle': 'Holding xALD will get rebase rewards everyday. Just hold !',
  'rebase.currentIndex': 'Current Index',
  'rebase.nextRebases': 'Next Rebases',
  'rebase.myBalance': 'My xALD Balance',
  'rebase.walletBalance': 'Wallet Balance',
  'rebase.stakedBalance': 'Staked Balance',
  'rebase.nextAmount': 'Next Reward Amount',
  'rebase.nextYield': 'Next Reward Yield',
  'rebase.action.title': 'Redeem xALD',
  'rebase.available': 'Available',
  'rebase.youWillReceive': 'You will receive',
  'rebase.unlocked': 'Unlocked',

  // mining UI
  'mining.banner': 'Farm With DeFi Big Brains',
  'mining.all': 'All',
  'mining.vault': 'Farm',
  'mining.staking': 'Stake',
  'mining.liquidity': 'Liquidity',
  'membership.title': 'DAO',
  'membership.subtitle':
    'Committee members can obtain ALDDAO and ALDVOTE by depositing USDC. ALD tokens will be distributed by staking ALDDAO. Please note, ALDVOTE cannot be transferred and is only used for Aladdin governance. Contract Address: ',
  'membership.limitHint': 'Only whitelist address is permitted to deposit. Please contact community administrator.',
  'membership.clickHere': 'Click here',
  'membership.whitelistAddress': 'Whitelist addresses',
  'membership.howTo': 'Join Our Family',
  'membership.familyPurpose': 'Family Purpose',
  'membership.familyMembers': 'Family Members',

  // vaults UI
  'vaults.vaultsTVL': 'Vaults TVL',
  'vaults.subtitle': 'Deposit Defi assets earns xALD.',
  'vaults.howMuchDeposit': 'How much do you want to deposit?',
  'vaults.howMuchWithdraw': 'How much do you want to withdraw?',
  'vaults.vestingTerm': 'Earnings Vesting Term',
  'vaults.earned': 'Earned',
  'vaults.unlocked': 'Unlocked',
  'vaults.locked': 'Locked',
  'vaults.releaseTime': 'Daily earnings will be released in 5 days.',
  'vaults.available': 'Available',
  'vaults.youCan': 'You can',
  'vaults.viewInStake': 'view in stake',

  // for bond prize
  'bondDraw.subtitle': 'Bond Prize for NFT Holder',
  'bondDraw.newRound': 'New Round',
  'bondDraw.upcoming': 'Upcoming',
  'bondDraw.draw': 'Draw',
  'bondDraw.firstPrize': 'First Prize',
  'bondDraw.oneWinner': 'One Winner',
  'bondDraw.secondPrize': 'Second Prize',
  'bondDraw.twoWinners': 'Two Winners',
  'bondDraw.thirdPrize': 'Third Prize',
  'bondDraw.threeWinners': 'Three Winners',
  'bondDraw.luckyPrize': 'Lucky Prize',
  'bondDraw.divide': 'Divide',
  'bondDraw.100Winners': 'Up to 100 Winners',
  'bondDraw.totalDrawTimes': 'Total Draw Times',
  'bondDraw.totalDrawPrize': 'Total Draw Prize',
  'bondDraw.desc1': 'The Prize pool is increased by 10% of the bond sale,to be drawn upon reaching 1,000,000 ALD.',
  'bondDraw.desc2': 'Continue to draw round after round.',
  'bondDraw.desc3': 'The more NFTs,the higher the Honor Score.Only 1 of each NFT is valid,and each NFT can be used for 10 draws.',
  'bondDraw.ownedNFT': 'Owned Honor NFT',
  'bondDraw.myScore': 'My Honor Score',
  'bondDraw.myRate': 'My Winning Rate',
  'bondDraw.myPrize': 'My Prize',
  'bondDraw.myRecord': 'My Winning Record',
  'bondDraw.nftDetails': 'NFT Details',
  'bondDraw.nftName': 'NFT Name',
  'bondDraw.score': 'Score',
  'bondDraw.availableTimes': 'Available Times',

  'bondNFT.subtitle': 'Cumulative Bond Rewards',
  'bondNFT.myBonds': 'My Bonds',
  'bondNFT.owned': 'Owned',
  'bondNFT.claimable': 'Claimable',

  // mystery school
  'mystery.title': 'Mystery School',
  'mystery.subtitle':
    'The Mystery School is where Initiates of AladdinDAO come to earn additional ALD rewards outside playing easy mode with staking and bonding. Here community bounties and reward programs are built and earned by the community.',

  // staking UI
  'staking.title': 'Staking',
  'staking.subtitle': 'Stake vault tokens and get Aladdin DAO Token',
  'staking.stakingTVL': 'Staking TVL',
  'staking.availableToClaim': 'ALD available to claim',
  'staking.aldTotalSupply': 'ALD Total Supply',
  'staking.aldBonusPerBlock': 'Liquidity Mining Rewards Per Block',

  // staking hint
  'stakingHint.getMore': 'Get',
  'stakingHint.goLiquidityPool': 'Liquidity Pool',
  'stakingHint.goEarn': 'Earn ALD',
  'stakingHint.aldStaking': 'Aladdin Staking',
  'stakingHint.learnMore': 'Learn more about AladdinDAO',
  'stakingHint.whenStake': 'AladdinDAO has a unique governance mechanism',
  'stakingHint.stakeEarn': 'Stake your {vaultTokenSymbol} tokens to earn ALD',
  'stakingHint.getRequiredDeposit': 'Get required {tokenSymbol} token before deposit',
  'stakingHint.getRequiredStaking': 'Get required {tokenSymbol} token before staking',
  // boule plus
  'boule.title.quit': 'Quit Boule Plus',
  'boule.unlocking': 'Unlocking',
  'boule.unlockTime': 'Will be unlocked at：',
  'boule.after.unlocking': 'After unlocking, you can claim your ALD',
  'boule.claimable': 'Claimable',
  'boule.bannerTitle': 'DAOFi: Boule Plus',
  'boule.bannerSubtitle': 'A Permissionless Yield Curation Platform Powered by Wisdom of the Crowd',
  'boule.what.title': 'Aladdin Boule Plus',
  'boule.what.1': 'Accumulate on-chain reputation through voting.',
  'boule.what.2': 'Fast-track to become Boule Member.',
  'boule.what.3': 'Priority access to Big Brain research meeting.',
  'boule.what.4': 'ALD holders maximize yield with vote-to-earn farming.',
  'boule.step.1': 'Stake {token} to receive ALDPLUS token.',
  'boule.step.2': 'Vote on liquidity mining project to earn rewards every 2 weeks.',
  'boule.step.3.title': 'Earn',
  'boule.step.3': 'Support an outperforming pool.',
  'boule.step.3.1': 'Oppose an underperforming pool.',
  'boule.step.4.title': 'Bonus',
  'boule.step.4': 'NFT for members who hold ALDPLUS for more than 3 months.',
  'boule.date': 'The First Year',
  'boule.rewardTitle': 'Vote2Earn',
  'boule.youAre.title': 'You are Boule Plus Member',
  'boule.youAre.1': '4 vote opportunities per month.',
  'boule.youAre.2': 'Research and vote.',
  'boule.votingEntrance': 'Vote',
  'boule.hold': 'Hold ALDPlus to get rewards.',
  'boule.unstakeAndLose': 'Unstake and Lose Rewards',
  'boule.become.title': 'Become a Boule Plus Member',
  'boule.become.hint1': 'Stake ',
  'boule.become.hint2': ' to join Boule Plus.',
  'boule.clickToStake': 'Stake',
  'boule.stake.hint1': 'Receive ALDPLUS as staking proof and represents your Boule Plus status.',
  'boule.stake.hint2': 'Unstake ALD at any time.',
  'boule.stake.hint3': 'Claim ALD after a 14-days lockup. ',
  'confirmUnstake.title': 'Confirm to Unstake',
  'confirmUnstake.desc': 'Unstake will quit from Boule Plus and you will not be able to get rewards from the Vault you voted.',
  'confirmUnstake.tip': 'After the {days} , you could claim your ALD',
  'notQualified.title': 'Become a Boule Plus Member',
  'notQualified.desc.1': 'You need to pass the quiz first.',
  'notQualified.desc.2': 'If you have passed, please make sure the address is correct.',
  // boule member
  'bouleMember.bannerTitle': 'Boule Member',
  'bouleMember.bannerSubtitle': 'Boule Members are top tier experts in DeFi. They identify the most promising DeFi projects.',
  'bouleMember.bannerData1': 'Total Profits',
  'bouleMember.bannerData2': 'Voting Boule Members',
  'bouleMember.bannerData3': 'Voted Projects',
  'bouleMember.bannerData4': 'ALD Rewards/Epoch',
  'bouleMember.moreInfo.title': 'More information',
  'bouleMember.lastEpochContributionValue': 'Last Epoch Contribution Value',
  'bouleMember.bouleMembers': 'Boule Members',
  'bouleMember.projects': 'Projects',
  'bouleMember.myReferral': 'My Referral',
  'bouleMember.totalCV': 'Total CV',
  'bouleMember.totalRewards': 'Total Rewards',
  'bouleMember.project': 'Project',
  'bouleMember.totalProfits': 'Total Profits',
  'bouleMember.lastEpochTVL': 'Last Epoch TVL',
  'bouleMember.thisEpochProfit': 'This Epoch Profit',
  'bouleMember.supportedVotes': 'Supported Votes',
  'bouleMember.daysSinceListed': 'Days since listed',
  'bouleMember.decayRate': 'Decay Rate',
  'bouleMember.averageValueOfAdjestedProfits': 'Average value of adjested profits',
  'bouleMember.adjustedProfitsOfStrategyDeveloper': 'Adjusted profits of strategy developer',
  'bouleMember.conteibutionRatloOfStrategyDeveloper': 'Conteibution ratlo of strategy developer',
  'bouleMember.strategyDeveloperRewards': 'Strategy developer rewards(ALD)',
  'bouleMember.totalContributionValue': 'Total Contribution Value',
  'bouleMember.votedBouleMember': 'Voted Boule Member',
  'bouleMember.myProfile': 'My Profile',
  'bouleMember.contributionValue': 'Contribution Value',
  'bouleMember.ratio': 'Ratio',
  'bouleMember.rewards': 'Rewards',
  'bouleMember.talentHunterRewards': 'Talent Hunter Rewards',
  'bouleMember.relatedProjectPerformance': 'Related Project Performance(this epoch)',
  'bouleMember.proposal': 'Proposal',
  'bouleMember.voteWeightOfThisBouleMember': 'Vote Weight of this Boule Member',
  'bouleMember.relativeProfits': 'Relative Profits',
  'bouleMember.proportionInContributionValue': 'Proportion in Contribution Value',
  // option reward UI
  'optionReward.title': 'Rewards',
  'optionReward.subtitle':
    '15% of the tokens on the Aladdin will be used to incentivize TVL growth. For every additional $100 million in TVL, 0.75% of ALD will be rewarded, and the maximum reward will be $2 billion in TVL. The incentive period is one month, starting from the creation of mining. After one month, users who pledge ALD will have the opportunity to get rewards from the growth of the TVL project on the platform.',
  'optionReward.aladdinStaking': 'Aladdin Staking',

  // staking reward UI
  'stakingReward.title': 'Aladdin Staking',

  'stakingReward.subtitle':
    'Token holders can stake ALD into the Aladdin Rewards pool and get the rewards from the listing option rewards once the target TVL is achieved.',

  'stakingReward.tableInfo': '',

  'vault.tableInfo': 'More info of this vault',
  'staking.tableInfo': 'More info of this staking pool',

  // liquidity UI
  'liquidity.title': 'Liquidity',
  'liquidity.subtitle': 'Liquidity description',
  'liquidity.tableInfo': 'Something about the project',

  // banners
  vaultBanner1: 'Get double mining rewards: {rewardSymbol}+ALD.',
  vaultBanner2: 'Step1: Deposit {deposit} in {poolName} and get {stakeSymbol}.',
  vaultBanner3: `Step2: Deposit {stakeSymbol} in Aladdin's Vault to earn {rewardSymbol} and get {vaultSymbol}.`,
  vaultBanner4: `Step3: Stake {vaultSymbol} in Aladdin's Staking pool to get ALD token rewards.`,
  // vaultBanner4: `Double mining rewards: {rewardSymbol}+ALD.`,
  // stakingBanner: `Stake the assets from Aladdin's Vault to Staking pool, can get bouble mining rewards: ALD token and original asset rewards. If there is no {stakeTokenSymbol}, please deposit {stakeToken} in Aladdin vault first.`,
  stakingBanner: `Get double mining rewards by staking assets from Aladdin Vault to Staking Pool. Deposit in Aladdin Vault first to get {stakeTokenSymbol}`,
  stakingBannerUni: `Get {stakeTokenSymbol} from Uniswap and stake here to earn ALD rewards.`,

  // vaultBanner1: 'Deposit {deposit} in {poolName} and get {stakeSymbol}.',
  // vaultBanner2: `Users deposits {stakeSymbol} in Aladdin's vault, {stakeSymbol} will be deposited by Aladdin protocol automatically on the {poolName} platform to earn {rewardSymbol} and interest. At the same time, users can also get {vaultSymbol}.`,
  // vaultBanner3: `Deposit {vaultSymbol} in Aladdin's staking pool to get ALD token rewards.`,
  // vaultBanner4: `Double mining rewards: {rewardSymbol}+ALD.`,
  // stakingBanner: `Users stake the assets from Aladdin's vault (such as Comp, CRV, Sushi, etc.) to Staking pool, they can get bouble mining rewards: ALD token and original asset rewards. If there is no {stakeTokenSymbol}, please deposit {stakeToken} in Aladdin vault first.`,

  // lbp pagge
  'lbp.title': 'Aladdin Token Initial Launch',
  'lbp.subtitle': '2021 Apr 6th 14:00:00(UTC) - 2021 Apr 8th 14:00:00(UTC)',
  'lbp.banner1': 'Trading Pair and Initial Amount',
  'lbp.banner2': 'Start Ratio (ALD:USDC)',
  'lbp.banner3': 'End Ratio (ALD:USDC)',
  'lbp.banner4': 'Starting Price',
  'lbp.aldBalance': 'ALD Balance',
  'lbp.usdcBalance': 'USDC Balance',
  'lbp.aldWeight': 'ALD Weight',
  'lbp.usdcWeight': 'USDC Weight',
  'lbp.aldSold': 'ALD Sold',
  'lbp.fundRaised': 'Fund Raised',
  'lbp.spotPrice': 'Spot Price',
  'lbp.terminal': 'Terminal Price if No One Swap',
  'lbp.swapOnBalance': 'Swap on Balancer',
  'lbp.warning1': 'Do not rush to buy or you will get rekt.',
  'lbp.warning2': 'Watch your gas fee and slippage settings.',
  'lbp.warning3': 'Do not create a new pool on Uniswap or Balancer before the end of the LBP (watch out for impermanent loss!)',
  'lbp.warning4': 'Be ware of scams, only use the ALD address announced by Aladdin official channels.',

  'countdown.estimateDate': 'Estimated Target Date',
  'countdown.rewardHint1': 'ALD rewards will be released at Block',
  'countdown.rewardHint2': ' ',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
