const CHAIN_ID = 42

const NETWORK_NAME = 'kovan'

const INFURA_ID = '8b64d38a34d7478dab30d9741fafa90f'
const INFURA_URL = `wss://kovan.infura.io/ws/v3/${INFURA_ID}`

const explorerUri = 'http://kovan.etherscan.io'

const enableCachedLpPrice = true

// There is no curve in kovan testnet so it uses the address of compound usdt
const contracts = {
  nativeToken: '0x30403605E199ae6C7650E6eA0Ff3A883A240E17f',
  wrappedNativeToken: '0x79B99F22d8d8DCeAD69d2acA90AEc9EB89ACC060',
  strategyController: '0x6aB2df271F1747CCAc85AD649a8eD178D058B749',

  strategyCompoundUsdc: '',
  strategyCompoundUSDT: '0x8397DF73E3e9381dFFbCBEb513AE73cfF5474C34',
  strategyCurveRenWBTC: '0x8397DF73E3e9381dFFbCBEb513AE73cfF5474C34',
  strategyCurveSETH: '0x8397DF73E3e9381dFFbCBEb513AE73cfF5474C34',
  strategyCurveAave3pool: '0x8397DF73E3e9381dFFbCBEb513AE73cfF5474C34',

  compoundUSDCVault: '',
  vaultCompoundUSDT: '0x1411a1a5B73C057Ed7B61246A17E892c11339f3D',
  curveRenWBTCVault: '0x1411a1a5B73C057Ed7B61246A17E892c11339f3D',
  curveSETHVault: '0x1411a1a5B73C057Ed7B61246A17E892c11339f3D',
  curveAave3poolVault: '0x1411a1a5B73C057Ed7B61246A17E892c11339f3D',

  tokenMaster: '0xD14fFAb5169598b9FA1A0a1e8657572779ed7af7',
  daoFunding: '0x8262edb4b9074f1e5b05B07F5E4E97717c284547',
  daoTreasury: '0xBa1cDf908976a2e3D7C5081279914cFA0b444463',
  tokenDistributor: '0x30d6f4afa4606B0Cc60f62aEE87d8AdFC0536226',
  rewardDistributor: '0xed2C5Baf4048AEB83ff7fc72b9e9A347017C7C34',
  multiStakingRewards: '0x8E49Ad6c8F4e2E348A120391D11B745a15f09995',
}

const tokens = {
  usdc: '0xb7a4F3E9097C08dA09517b5aB877F7a917224ede',
  usdt: '0x07de306FF27a2B630B1141956844eB1552B956B5',
  wbtc: '0xe0C9275E44Ea80eF17579d33c55136b7DA269aEb',
  weth: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
  dai: '0x1528F3FCc26d13F7079325Fb78D9442607781c8C',

  crvRenWBTC: '',
  eCRV: '',
  a3CRV: '',

  slpETHUSDC: '',
  slpETHWBTC: '',
  slpALDETH: '',
  slpALDUSDC: '',

  comp: '0x61460874a7196d6a22D1eE4922473664b3E95270',
  crv: '',
  sushi: '',
}

const chainUnit = 'ETH'
const chainDecimal = 18

export default {
  INFURA_ID,
  INFURA_URL,
  CHAIN_ID,
  NETWORK_NAME,
  explorerUri,
  tokens,
  contracts,
  chainUnit,
  chainDecimal,
  enableCachedLpPrice,
}
