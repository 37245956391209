import React, { useContext } from 'react'
import { Modal } from 'antd'
import { Web3Context } from 'context/Web3Context'
import './style.scss'

export default function BalanceModal(props) {
  const { onCancel } = props
  const { chainBalance, aldBalance } = useContext(Web3Context)

  return (
    <Modal title="Balances" visible onCancel={onCancel} footer={null} wrapClassName="balance-modal">
      <div className="balance-line">
        <img className="icon" alt="ald-token" src="/resources/tokens/ald.svg" />
        <div className="value">{aldBalance} ALD</div>
      </div>
      <div className="balance-line">
        <img className="icon" alt="eth-token" src="/resources/tokens/eth.svg" />
        <div className="value">{chainBalance}</div>
      </div>
    </Modal>
  )
}
