import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  main: MainLayout,
}

console.log(`
     /\\   | |        /\\   |  __ \\|  __ \\_   _| \\ | | |  __ \\   /\\   / __ \\ 
    /  \\  | |       /  \\  | |  | | |  | || | |  \\| | | |  | | /  \\ | |  | |
   / /\\ \\ | |      / /\\ \\ | |  | | |  | || | | .  | | |  | |/ /\\ \\| |  | |
  / ____ \\| |____ / ____ \\| |__| | |__| || |_| |\\  | | |__| / ____ \\ |__| |
 /_/    \\_\\______/_/    \\_\\_____/|_____/_____|_| \\_| |_____/_/    \\_\\____/ 
`)

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    // if (!isAuthLayout && !isUserAuthorized) {
    //   return <Redirect to="/auth/login" />
    // }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="AladdinDAO | %s" title="AladdinDAO" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
