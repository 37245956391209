const { NETWORK_ENV } = process.env

// eslint-disable-next-line import/no-mutable-exports
let vaults = {}

switch (NETWORK_ENV) {
  case 'kovan':
    vaults = require('./kovan').default
    break
  case 'mainnet':
    vaults = require('./mainnet').default
    break
  case 'mainnet-fork':
    vaults = require('./mainnet-fork').default
    break
  default:
    vaults = require('./mainnet').default
}

export default vaults
