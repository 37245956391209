import React, { useEffect, useMemo, useState, useCallback } from 'react'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import abi from '../utils/allabis'
import config from '../config'
import ethProvider from 'eth-provider'

import {
  useWeb3Modal,
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useDisconnect,
} from '@web3modal/ethers5/react'

const { INFURA_URL } = config

const projectId = 'a09980363a7c82db2cc300485b9469c5'

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
}

const metadata = {
  name: 'Aladdin',
  description: 'Aladdin App',
  url: 'https://app.aladdin.club',
  icons: [''],
}

const ethersConfig = defaultConfig({
  metadata,
  rpcUrl: 'https://cloudflare-eth.com',
  defaultChainId: 1,
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
})

export const Web3Context = React.createContext({
  web3: null,
  setWeb3: {},
  currentAccount: null,
  currentBlock: 0,
  currentChainId: 0,
  chainBalance: 0,
  aldBalance: 0,
  connectWallet: async () => {},
  getChainBalance: async () => {},
  getErc20Balance: async () => {},
  getErc20BalanceInWei: async () => {},
  getTotalSupply: async () => {},
  resetAccount: async () => {},
  updateAldBalance: async () => {},
  updateChainBalance: async () => {},
})

function initWeb3(provider) {
  const web3 = new Web3(provider)

  web3.eth.transactionConfirmationBlocks = 1

  return web3
}

export const Web3ContextProvider = ({ children }) => {
  const [web3, setWeb3] = useState('')
  const [provider, setProvider] = useState(null)
  const [onBlockListeners, setOnBlockListeners] = useState({})
  const [currentBlock, setCurrentBlock] = useState('')
  const [chainBalance, setChainBalance] = useState(0)
  const [aldBalance, setAldBalance] = useState(0)
  const { address: currentAccount, chainId: currentChainId = 1, isConnected } = useWeb3ModalAccount()

  console.log('currentChainId-----', currentChainId)
  const { walletProvider } = useWeb3ModalProvider()
  const { open } = useWeb3Modal()
  const { disconnect } = useDisconnect()

  const resetAccount = async () => {
    disconnect()
  }

  useEffect(() => {
    if (walletProvider) {
      try {
        const web3New = initWeb3(walletProvider)
        setWeb3(web3New)

        setProvider(walletProvider)
      } catch (err) {
        console.log('User rejected connect, set infura web3')
        setWeb3(new Web3(ethProvider(INFURA_URL)))
      }
    } else {
      setWeb3(new Web3(ethProvider(INFURA_URL)))
    }
  }, [walletProvider])

  const getCurrentBlock = useCallback(async () => {
    let currentBlockData = 0
    try {
      currentBlockData = await web3.eth.getBlockNumber()
    } catch (e) {
      console.error(e)
    }
    return currentBlockData
  }, [web3])

  const getChainBalance = useCallback(async () => {
    if (!currentAccount) return 0
    const balanceRaw = await web3.eth.getBalance(currentAccount)
    const balance = new BigNumber(balanceRaw).shiftedBy(-config.chainDecimal).toFixed(4)
    return balance
  }, [web3, currentAccount])

  const getErc20Balance = useCallback(
    async (contract, decimal) => {
      if (!currentAccount) return 0
      if (!contract) return 0
      const balanceInWei = await contract.methods.balanceOf(currentAccount).call()
      const balance = new BigNumber(balanceInWei).div(new BigNumber(10).pow(decimal)).toString()
      return balance || 0
    },
    [web3, currentAccount],
  )

  const getErc20BalanceInWei = useCallback(
    async (contractAddress) => {
      if (web3 && currentAccount) {
        const erc20 = await new web3.eth.Contract(abi.erc20ABI, contractAddress)
        const balanceInWei = await erc20.methods.balanceOf(currentAccount).call()
        return balanceInWei || 0
      }
    },
    [web3, currentAccount],
  )

  const getTotalSupply = useCallback(
    async (contractAddress) => {
      if (web3) {
        const erc20 = await new web3.eth.Contract(abi.erc20ABI, contractAddress)
        const totalSupply = await erc20.methods.totalSupply().call()
        return totalSupply
      }
    },
    [web3],
  )

  const addOnBlockListener = useCallback(
    (name, listener) => {
      setOnBlockListeners((old) => ({ ...old, [name]: listener }))
    },
    [setOnBlockListeners],
  )

  const removeOnBlockListener = useCallback(
    (name) => {
      setOnBlockListeners((old) => {
        delete old[name]
        return old
      })
    },
    [setOnBlockListeners],
  )

  useEffect(() => {
    if (web3 && currentChainId === config.CHAIN_ID) {
      let subscribe = null
      const onBlock = async (block) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const listener of Object.entries(onBlockListeners)) {
          // eslint-disable-next-line no-await-in-loop
          await listener[1]?.(block)
        }
        console.log('current block height', block.number)
        if (currentBlock !== block.number) {
          setCurrentBlock(block.number)
        }
      }
      subscribe = web3.eth.subscribe('newBlockHeaders', (error, result) => {
        onBlock(result)
      })
      return () => {
        try {
          if (subscribe) {
            subscribe.unsubscribe((error, success) => {
              if (error) {
                console.error(error)
                return
              }
              console.log(success)
            })
          }
        } catch (error) {}
      }
    }
  }, [provider, currentChainId, onBlockListeners])

  // Todo:: change to use wei
  const updateChainBalance = async () => {
    const balance = await getChainBalance()
    setChainBalance(`${balance} ${config.chainUnit}`)
  }

  const updateAldBalance = useCallback(async () => {
    if (!currentAccount) return 0
    const nativeTokenContract = new web3.eth.Contract(abi.erc20ABI, config.contracts.nativeToken)
    const aldBalanceInWei = await nativeTokenContract.methods.balanceOf(currentAccount).call()
    setAldBalance(new BigNumber(aldBalanceInWei).div(new BigNumber(10).pow(18)).toString())
  }, [web3, currentAccount])

  useEffect(() => {
    if (!web3 || !currentAccount) {
      return
    }
    updateChainBalance()
    updateAldBalance()
  }, [web3, currentAccount])

  useEffect(() => {
    if (web3 && currentAccount && currentBlock % 30 === 0) {
      updateChainBalance()
      updateAldBalance()
    }
  }, [web3, currentAccount, currentBlock])

  return (
    <Web3Context.Provider
      value={{
        web3,
        setWeb3,
        currentAccount,
        currentBlock,
        currentChainId,
        chainBalance,
        connected: isConnected,
        aldBalance,
        connectWallet: open,
        getChainBalance,
        getErc20Balance,
        getErc20BalanceInWei,
        getTotalSupply,
        resetAccount,
        addOnBlockListener,
        removeOnBlockListener,
        updateChainBalance,
        updateAldBalance,
        getCurrentBlock,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}
export const Web3ContextConsumer = Web3Context.Consumer
