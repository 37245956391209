import config from '../index'

const pools = [
  {
    logo: '🦄️',
    pool_id: 5,
    name: 'Uniswap ETH/ALD LP',
    description: 'Stake ETH/ALD UNI LP and earn ALD',
    basicAPY: 10.12,
    rewardAPY: '> 10000',
    type: 'staking',
    tokens: ['eth', 'ald'],
    stakeTokenSymbol: 'uniLP-v2-ETH/ALD',
    stakeTokenContractAddress: config.tokens.unilpALDETH,
    stakeTokenDecimals: 18,
    tvlPriceTokenId: 'uniLP-v2-ETH/ALD',
    underlyingAssets: [
      ['ethereum', config.tokens.weth, 18],
      ['ald', config.contracts.nativeToken, 18],
    ],
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'uni',
    refOutsideUrl: 'https://app.uniswap.org/#/add/v2/ETH/0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
  },
  {
    logo: '🦄️',
    pool_id: 4,
    name: 'Uniswap USDC/ALD LP',
    description: 'Deposit USDC/ALD UNI LP and earn ALD',
    basicAPY: 10.12,
    rewardAPY: '> 10000',
    type: 'staking',
    tokens: ['usdc', 'ald'],
    stakeTokenSymbol: 'uniLP-v2-USDC/ALD',
    stakeTokenContractAddress: config.tokens.unilpALDUSDC,
    stakeTokenDecimals: 18,
    tvlPriceTokenId: 'uniLP-v2-USDC/ALD',
    underlyingAssets: [
      ['usd-coin', config.tokens.usdc, 6],
      ['ald', config.contracts.nativeToken, 18],
    ],
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'uni',
    refOutsideUrl:
      'https://app.uniswap.org/#/add/v2/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
  },
  {
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 1,
    name: 'aldCurve 3Pool',
    description: 'Double Mining with Curve USDT(Curve 3Pool)',
    basicAPY: 2.63,
    rewardAPY: 0,
    type: 'staking',
    tokens: ['usdt'],
    stakeTokenSymbol: 'ald3CRV',
    stakeTokenContractAddress: config.contracts.vaultCurve3Pool,
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
    refOutsideUrl: `#/vault?vault_id=3`,
  },
  {
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 3,
    name: 'aldCurve SETH',
    description: 'Double Mining with Curve eCRV (Curve SETH Pool)',
    basicAPY: 0.91,
    rewardAPY: 0,
    type: 'staking',
    tokens: ['eth'],
    stakeTokenSymbol: 'aldeCRV',
    stakeTokenContractAddress: config.contracts.vaultCurveSETH,
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
    refOutsideUrl: '#/vault?vault_id=2',
  },
  {
    logoImg: '/resources/tokens/crv.svg',
    pool_id: 0,
    name: 'aldCurve renWBTC',
    description: 'Double Mining with Curve crvRenWBTC (Curve ren Pool)',
    basicAPY: 2.27,
    rewardAPY: 0,
    type: 'staking',
    tokens: ['btc'],
    stakeTokenSymbol: 'aldcrvRenWBTC',
    stakeTokenContractAddress: config.contracts.vaultCurveRenWBTC,
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'crv',
    refOutsideUrl: '#/vault?vault_id=1',
  },
  {
    logo: '🍣',
    pool_id: 2,
    name: 'aldSushi ETH/WBTC SLP',
    description: 'Double Mining with Sushi ETH/WBTC SLP',
    basicAPY: 3.25,
    rewardAPY: 0,
    type: 'staking',
    tokens: ['btc', 'eth'],
    stakeTokenSymbol: 'ETH/WBTC aldSLP',
    stakeTokenContractAddress: config.contracts.vaultSushiETHWBTC,
    stakeTokenDecimals: 18,
    rewardTokenSymbol: 'ALD',
    rewardTokenDecimals: 18,
    site: 'sushi',
    refOutsideUrl: `#/vault?vault_id=4`,
  },
]

export default pools
