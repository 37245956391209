import React, { useContext, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
// import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Button } from 'antd'
import { Web3Context } from '../../../../../context/Web3Context'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch }) => {
  // const wallet = useWallet()
  // const [currentAccount, setCurrentAccount] = useState('')

  const { currentAccount, connectWallet, resetAccount } = useContext(Web3Context)

  const doConnect = useCallback(() => {
    return connectWallet()
  }, [])

  // useEffect(()=>{
  //   const getAccount = async () => {
  //     const accounts = await web3?.eth?.getAccounts()
  //     if (accounts?.length > 0) {
  //       setCurrentAccount(accounts[0])
  //     }
  //   }
  //   if(web3){
  //     getAccount()
  //   }
  // }, [web3])

  const logout = e => {
    resetAccount()
    // wallet.reset()
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="action.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      {currentAccount ? (
        <Dropdown overlay={menu} trigger={['click']}>
          <div className={styles.dropdown}>{`${currentAccount.slice(0, 4)}...${currentAccount.slice(-4)}`}</div>
        </Dropdown>
      ) : (
        <Button type="primary" onClick={doConnect}>
          <FormattedMessage id="common.connectWallet" />
        </Button>
      )}
    </>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
