const { NETWORK_ENV } = process.env

// Configs irrelated to network
const CHAIN_MAPPING = {
  1: 'Mainnet',
  3: 'Ropsten',
  42: 'Kovan',
  4: 'Rinkeby',
  128: 'Heco',
  256: 'Heco Test',
}

const coingeckoURL = 'https://api.coingecko.com/api/v3'

// new Date('2021-09-11 00:01:33').valueOf()
const stakingStartTime = 1631289693000

// Configs related to netwwork

let envConf = {}

switch (NETWORK_ENV) {
  case 'kovan':
    envConf = require('./kovan').default
    break
  case 'mainnet':
    envConf = require('./mainnet').default
    break
  case 'mainnet-fork':
    envConf = require('./mainnet-fork').default
    break
  default:
    envConf = require('./mainnet').default
}

// console.log(envConf, 'env conf')

export default {
  coingeckoURL,
  CHAIN_MAPPING,
  ...envConf,
  stakingStartTime,
}
